import http from "./httpService";

const url = "/lectors";
const types_url = "/lector_types";

export async function getLectors() {
  const { data } = await http.get(url);
  return data;
}

export async function getLectorTypes() {
  const { data } = await http.get(types_url);
  return data;
}

export async function getLector(id) {
  const { data } = await http.get(`${url}/${id}`);
  return data;
}

export function saveLector(lector) {
  const data = { ...lector };

  if (lector._id) {
    delete data._id;
    data.lidi.forEach((clovek) => {
      delete clovek._id;
      return clovek;
    });

    return http.put(`${url}/${lector._id}`, data);
  }

  return http.post(url, data);
}

export function deleteLector(id) {
  return http.delete(`${url}/${id}`);
}
