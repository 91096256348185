import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

import logo from "../assets/logo.png";

class NavBar extends Component {
  render() {
    const { user } = this.props;

    return (
      <nav className="navbar navbar-expand-lg navbar-dark mb-3">
        <NavLink className="navbar-brand" to="/">
          <img
            src={logo}
            alt="Respir - UP"
            width="50"
            height="50"
            className="d-inline-block align-top"
            loading="lazy"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          {user && (
            <ul className="navbar-nav">
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/pacienti">
                  <i className="fa fa-user"></i>
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/osetreni">
                  <i className="fa fa-plus-square"></i>
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/naseptavac">
                  <i className="fa fa-commenting"></i>
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/skupiny">
                  <i className="fa fa-users"></i>
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/lektorstvi">
                  <i className="fa fa-graduation-cap"></i>
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/faktury">
                  <i className="fa fa-file-pdf-o"></i>
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/kalendar">
                  <i className="fa fa-calendar"></i>
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/statistiky">
                  <i className="fa fa-bar-chart"></i>
                </NavLink>
              </li>
            </ul>
          )}
          <ul className="navbar-nav ml-auto">
            {!user && (
              <li className="nav-item">
                <NavLink className="nav-link" to="/prihlaseni">
                  <i className="fa fa-sign-in"></i>
                </NavLink>
              </li>
            )}
            {user && (
              <Fragment>
                <li className="nav-item nav-link">{user.jmeno}</li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/logout">
                    <i className="fa fa-sign-out"></i>
                  </NavLink>
                </li>
              </Fragment>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavBar;
