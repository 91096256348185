import React, { Fragment } from "react";
import { getPacient, savePacient } from "../services/pacientsService";
import { getWhisperers } from "../services/whispererService";
import Form from "./common/form";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

class PacientForm extends Form {
  state = {
    data: {
      jmeno: "",
      prijmeni: "",
      rodneCislo: "",
      telefon: "",
      ulice: "",
      obec: "",
      psc: "",
      email: "",
      pohlavi: "",
      ico: "",
      dic: "",
      rozbor: "",
      srdce: "",
      gynekologieAndrologie: "",
      zazivani: "",
      kosti: "",
      krev: "",
      urologie: "",
      nadory: "",
      plice: "",
      operace: "",
      medikamenty: "",
      ostatni: "",
      cil: "",
    },
    errors: {},
    whisperers: [],
  };

  schema = {
    _id: Joi.string(),
    datum: Joi.date(),
    jmeno: Joi.string()
      .min(2)
      .max(20)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    prijmeni: Joi.string()
      .min(2)
      .max(30)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    rodneCislo: Joi.string()
      .min(5)
      .max(15)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    telefon: Joi.string()
      .min(5)
      .max(20)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    ulice: Joi.string()
      .min(2)
      .max(60)
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    obec: Joi.string()
      .min(2)
      .max(50)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    psc: Joi.string()
      .min(2)
      .max(7)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    pohlavi: Joi.string()
      .valid("muž", "žena")
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    ico: Joi.string()
      .min(8)
      .max(8)
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    dic: Joi.string()
      .min(8)
      .max(12)
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    rozbor: Joi.string()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    srdce: Joi.string().allow(""),
    gynekologieAndrologie: Joi.string().allow(""),
    zazivani: Joi.string().allow(""),
    kosti: Joi.string().allow(""),
    krev: Joi.string().allow(""),
    urologie: Joi.string().allow(""),
    nadory: Joi.string().allow(""),
    plice: Joi.string().allow(""),
    operace: Joi.string().allow(""),
    medikamenty: Joi.string().allow(""),
    ostatni: Joi.string().allow(""),
    cil: Joi.string().allow(""),
  };

  populatePacient = async () => {
    try {
      const pacientId = this.props.match.params.id;
      if (pacientId === "new") return;

      const pacient = await getPacient(pacientId);
      this.setState({ data: this.mapToViewModel(pacient) });
    } catch (e) {
      if (e.response && e.response.status === 404)
        return this.props.history.replace("/not-found");
    }
  };

  async componentDidMount() {
    await this.populatePacient();
    this.setState({ whisperers: await getWhisperers() });
  }

  mapToViewModel = (pacient) => {
    return {
      _id: pacient._id,
      datum: pacient.datum,
      jmeno: pacient.jmeno,
      prijmeni: pacient.prijmeni,
      rodneCislo: pacient.rodneCislo,
      telefon: pacient.telefon,
      ulice: pacient.adresa.ulice || "",
      obec: pacient.adresa.obec,
      psc: pacient.adresa.psc,
      email: pacient.email,
      pohlavi: pacient.pohlavi,
      ico: pacient.ico || "",
      dic: pacient.dic || "",
      rozbor: pacient.rozbor,
      srdce: pacient.anamneza ? pacient.anamneza.srdce || "" : "",
      gynekologieAndrologie: pacient.anamneza
        ? pacient.anamneza.gynekologieAndrologie || ""
        : "",
      zazivani: pacient.anamneza ? pacient.anamneza.zazivani || "" : "",
      kosti: pacient.anamneza ? pacient.anamneza.kosti || "" : "",
      krev: pacient.anamneza ? pacient.anamneza.krev || "" : "",
      urologie: pacient.anamneza ? pacient.anamneza.urologie || "" : "",
      nadory: pacient.anamneza ? pacient.anamneza.nadory || "" : "",
      plice: pacient.anamneza ? pacient.anamneza.plice || "" : "",
      operace: pacient.anamneza ? pacient.anamneza.operace || "" : "",
      medikamenty: pacient.anamneza ? pacient.anamneza.medikamenty || "" : "",
      ostatni: pacient.anamneza ? pacient.anamneza.ostatni || "" : "",
      cil: pacient.anamneza ? pacient.anamneza.cil || "" : "",
    };
  };

  doSubmit = async () => {
    const data = { ...this.state.data };

    for (let e in data) {
      if (data[e] === "") data[e] = undefined;
    }

    await savePacient(data);
    toast.success("Pacient uložen");
    this.props.history.push(
      this.props.match.params.id === "new"
        ? "/pacienti"
        : `/pacienti/${this.props.match.params.id}`
    );
  };

  handleWhisperer = (text) => {
    const data = { ...this.state.data };
    data.rozbor = data.rozbor + text;

    this.setState({ data });
  };

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 text-right">
            <Link
              to={
                this.props.match.params.id === "new"
                  ? "/pacienti"
                  : `/pacienti/${this.props.match.params.id}`
              }
              className="btn btn-secondary"
            >
              Zpět
            </Link>
          </div>
        </div>
        <hr className="col-xs-12"></hr>
        <div className="treatmentForm mb-3">
          <h1 className="page-title">
            {this.props.history.location.pathname.includes("new")
              ? "Nový pacient"
              : "Editace"}
          </h1>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("jmeno", "Jméno")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("prijmeni", "Příjmení")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("rodneCislo", "Rodné číslo")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderSelect("pohlavi", "Pohlaví", [
                  { _id: "muž", name: "muž" },
                  { _id: "žena", name: "žena" },
                ])}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("email", "Email")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("telefon", "Telefon")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("ico", "IČO")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("dic", "DIČ")}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-sm-12">
                {this.renderInput("ulice", "Ulice")}
              </div>
              <div className="col-lg-5 col-sm-12">
                {this.renderInput("obec", "Obec")}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderInput("psc", "PSC")}
              </div>
            </div>
            {this.renderTextArea("rozbor", "Kineziologický rozbor")}
            {this.state.whisperers.map((w) => (
              <button
                key={w._id}
                type="button"
                className="btn btn-light btn-sm mr-2 mb-2"
                onClick={() => this.handleWhisperer(w.text)}
              >
                {w.zkratka}
              </button>
            ))}
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("srdce", "Srdce")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput(
                  "gynekologieAndrologie",
                  "Gynekologie / Andrologie"
                )}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("zazivani", "Zažívání")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("kosti", "Kosti")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("krev", "Krev")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("urologie", "Urologie")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("nadory", "Nádory")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("plice", "Plíce")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("operace", "Operace")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("medikamenty", "Medikamenty")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("ostatni", "Ostatní")}
              </div>
              <div className="col-lg-3 col-sm-12">
                {this.renderInput("cil", "Cíl")}
              </div>
            </div>
            {this.renderButton("Uložit")}
          </form>
        </div>
      </Fragment>
    );
  }
}

export default PacientForm;
