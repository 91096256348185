import React, { Component } from "react";
import Table from "./common/table";
import { Link } from "react-router-dom";

class PacientsTable extends Component {
  columns = [
    {
      label: "Jméno a Příjmení",
      path: "prijmeni",
      additionalPath: "jmeno",
      content: (pacient) => (
        <Link
          to={`/pacienti/${pacient._id}`}
          className="text-dark text-decoration-none table-title"
        >
          {pacient.jmeno} {pacient.prijmeni}
        </Link>
      ),
    },
    { label: "Datum přidání", path: "datum" },
    {
      key: "edit",
      content: (pacient) => (
        <button
          onClick={() => this.props.onEdit(pacient)}
          className="btn btn-info btn-sm"
        >
          Editovat
        </button>
      ),
    },
    {
      key: "delete",
      content: (pacient) => (
        <button
          onClick={() => this.props.onDelete(pacient)}
          className="btn btn-danger btn-sm"
        >
          Smazat
        </button>
      ),
    },
  ];

  render() {
    const { data, sortColumn, onSort } = this.props;
    return (
      <div className="responsive-table-scroll">
        <Table
          data={data}
          columns={this.columns}
          sortColumn={sortColumn}
          onSort={onSort}
        />
      </div>
    );
  }
}

export default PacientsTable;
