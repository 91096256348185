import React, { Component } from "react";
import Table from "./common/table";
import { HashLink as Link } from "react-router-hash-link";

class TreatmentsTable extends Component {
  columns = [
    {
      label: "Jméno a Příjmení",
      path: "pacient.prijmeni",
      additionalPath: "pacient.jmeno",
      content: (treatment) => (
        <Link
          to={`/pacienti/${treatment.pacient._id}#heading${treatment._id}`}
          className="text-dark text-decoration-none"
        >
          {treatment.pacient.jmeno} {treatment.pacient.prijmeni}
        </Link>
      ),
    },
    {
      label: "Datum",
      path: "datum",
      additionalPath: "cas.od", // Kvůli řazení terapií postupně
    },
    {
      label: "Čas",
      path: "cas.od",
      content: (treatment) => (
        <span>
          {treatment.cas.od} - {treatment.cas.do}
        </span>
      ),
    },
    {
      label: "Typ",
      path: "typ.nazev",
    },
    {
      label: "Cena",
      path: "vyuctovani.cena",
    },
    {
      label: "Vyúčtování",
      path: "vyuctovani.typBill.nazev",
    },
    {
      label: "Číslo",
      path: "vyuctovani.cislo",
    },
  ];

  render() {
    const { data, sortColumn, onSort } = this.props;

    return (
      <div className="responsive-table-scroll">
        <Table
          data={data}
          columns={this.columns}
          sortColumn={sortColumn}
          onSort={onSort}
        />
      </div>
    );
  }
}

export default TreatmentsTable;
