import React, { Fragment } from "react";
import Form from "./../common/form";
import Joi from "joi-browser";
import moment from "moment";

import { getBillTypes } from "../../services/treatmentService";
import {
  getLectorTypes,
  getLector,
  saveLector,
} from "../../services/lectorsService";
import { toast } from "react-toastify";

class LectorForm extends Form {
  state = {
    data: {
      typ: "",
      datum: moment(Date.now())
        .set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
        .format(),
      od: "",
      do: "",
      organizator: "",
      pocetLidi: "",
      lidi: [],
      nekdoJiny: {},
    },
    generateJa: false,
    generateNekdoJiny: false,
    billTypes: [],
    lectorTypes: [],
    errors: {},
    lectorErrors: {},
    fakturaErrors: {},
  };

  clovek = {
    _id: Joi.string(),
    jmeno: Joi.string()
      .min(2)
      .max(20)
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    prijmeni: Joi.string()
      .min(2)
      .max(30)
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    telefon: Joi.string()
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    email: Joi.string()
      .email()
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    ulice: Joi.string()
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    obec: Joi.string()
      .min(2)
      .max(50)
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    psc: Joi.string()
      .min(2)
      .max(7)
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    typBillId: Joi.string().error((errors) => {
      this.joiCzechMessages(errors);
      return errors;
    }),
    cena: Joi.number().error((errors) => {
      this.joiCzechMessages(errors);
      return errors;
    }),
    cislo: Joi.string()
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
  };

  faktura = {
    ico: Joi.string().error((errors) => {
      this.joiCzechMessages(errors);
      return errors;
    }),
    cislo: Joi.string().error((errors) => {
      this.joiCzechMessages(errors);
      return errors;
    }),
    cena: Joi.number().error((errors) => {
      this.joiCzechMessages(errors);
      return errors;
    }),
    cisloUctu: Joi.string().error((errors) => {
      this.joiCzechMessages(errors);
      return errors;
    }),
    jmeno: Joi.string()
      .min(2)
      .max(20)
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    prijmeni: Joi.string()
      .min(2)
      .max(30)
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    ulice: Joi.string()
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    obec: Joi.string()
      .min(2)
      .max(50)
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    psc: Joi.string()
      .min(2)
      .max(7)
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
  };

  schema = {
    _id: Joi.string().error((errors) => {
      this.joiCzechMessages(errors);
      return errors;
    }),
    typ: Joi.string()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    datum: Joi.date()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    od: Joi.date()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    do: Joi.date()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    organizator: Joi.string()
      .valid("já", "někdo jiný")
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    pocetLidi: Joi.number()
      .min(1)
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    lidi: Joi.array().items(Joi.object().keys(this.clovek)),
    nekdoJiny: Joi.object().keys(this.faktura),
  };

  populateLector = async () => {
    try {
      const lectorId = this.props.id;
      if (lectorId === "new") return;

      const lector = await getLector(lectorId);
      this.setState({ data: this.mapToViewModel(lector) });
    } catch (e) {
      if (e.response && e.response.status === 404)
        return this.props.history.replace("/not-found");
    }
  };

  async componentDidMount() {
    await this.populateLector();

    const billTypes = await getBillTypes();
    const lectorTypes = await getLectorTypes();

    this.setState({ billTypes, lectorTypes });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ generateJa: false, generateNekdoJiny: false });
      await this.populateLector();
    }
  }

  mapToViewModel = (lector) => {
    const obj = {
      _id: lector._id,
      typ: lector.typ._id,
      datum: lector.datum,
      od: lector.cas.od,
      do: lector.cas.do,
      organizator: lector.organizator,
      pocetLidi: lector.pocetLidi,
    };

    if (lector.lidi) {
      obj.lidi = lector.lidi;
    }

    if (lector.nekdoJiny) {
      obj.nekdoJiny = lector.nekdoJiny;
    }

    return obj;
  };

  doSubmit = async () => {
    const data = { ...this.state.data };

    if (
      data.pocetLidi.toString() !== data.lidi.length.toString() &&
      data.organizator === "já"
    ) {
      return toast.error("Počet lidí se musí rovnat počtu vygenerovaných lidí");
    }

    if (data.lidi.length > 0 && data.organizator === "někdo jiný") {
      return toast.error("Organizátor a data se neshodují");
    }

    if (
      Object.keys(data.nekdoJiny).length === 0 &&
      data.organizator === "někdo jiný"
    ) {
      return toast.error("Informace o organizátorovi musí být vyplněné");
    }

    if (data.lidi.length > 0) {
      for (let e of data.lidi) {
        if (e.ulice === "") e.ulice = undefined;
        if (e.cislo) e.cislo = e.cislo.split("/")[0];
      }
    }

    if (data.nekdoJiny && Object.keys(data.nekdoJiny).length > 0) {
      data.nekdoJiny.cislo = data.nekdoJiny.cislo.split("/")[0];
    }

    await saveLector(data);
    toast.success("Skupina uložena");
    this.props.didSave();
  };

  // custom handlers and renders because of nested "lidi" array of objects
  validateLectorProperty = (property) => {
    const { name, value } = property;

    const obj = { [name]: value };
    const schema = { [name]: this.clovek[name] };

    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  handleLectorChange = (event) => {
    const { name, value } = event.currentTarget;
    const id = event.currentTarget.getAttribute("data-position");

    const lectorErrors = { ...this.state.lectorErrors };
    const errorMessage = this.validateLectorProperty(event.currentTarget);
    if (errorMessage) lectorErrors[name] = { id, errorMessage };
    else delete lectorErrors[name];

    const data = { ...this.state.data };
    data.lidi[id][name] = value;

    this.setState({ data, lectorErrors });
  };

  renderLectorInput(name, label, position, type = "text") {
    const error = this.state.lectorErrors[name];

    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          value={this.state.data.lidi[position][name] || ""}
          onChange={this.handleLectorChange}
          type={type}
          name={name}
          id={name + position}
          className="form-control"
          data-position={position}
        />
        {error && error.id === position.toString() && (
          <div className="alert alert-danger">{error.errorMessage}</div>
        )}
      </div>
    );
  }

  renderLectorSelect(name, label, options, position) {
    const error = this.state.lectorErrors[name];
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>

        <select
          name={name}
          id={name + position}
          className="form-control"
          value={this.state.data.lidi[position][name]}
          onChange={this.handleLectorChange}
          data-position={position}
        >
          <option value=""></option>
          {options.map((e) => (
            <option key={e._id} value={e._id}>
              {e.name || e.nazev}
            </option>
          ))}
        </select>
        {error && error.id === position.toString() && (
          <div className="alert alert-danger">{error.errorMessage}</div>
        )}
      </div>
    );
  }

  validateFakturaProperty = (property) => {
    const { name, value } = property;

    const obj = { [name]: value };
    const schema = { [name]: this.faktura[name] };

    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  handleFakturaChange = (event) => {
    const { name, value } = event.currentTarget;

    const fakturaErrors = { ...this.state.fakturaErrors };
    const errorMessage = this.validateFakturaProperty(event.currentTarget);
    if (errorMessage) fakturaErrors[name] = { errorMessage };
    else delete fakturaErrors[name];

    const data = { ...this.state.data };
    data.nekdoJiny[name] = value;

    this.setState({ data, fakturaErrors });
  };

  renderFakturaInput(name, label, type = "text") {
    const error = this.state.fakturaErrors[name];

    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          value={this.state.data.nekdoJiny[name] || ""}
          onChange={this.handleFakturaChange}
          type={type}
          name={name}
          id={name}
          className="form-control"
        />
        {error && (
          <div className="alert alert-danger">{error.errorMessage}</div>
        )}
      </div>
    );
  }

  handleGenerateForms = () => {
    if (this.state.data.organizator === "" || this.state.data.pocetLidi === "")
      return toast.error("Vyber organizátora a počet lidí");

    if (this.state.data.organizator === "já") {
      const { data } = this.state;

      if (data.pocetLidi <= data.lidi.length) {
        const repeat = data.lidi.length - data.pocetLidi;

        for (let i = 0; i < repeat; i++) {
          data.lidi.pop();
        }

        data.nekdoJiny = {};

        this.setState({
          data,
          generateJa: data.pocetLidi,
          generateNekdoJiny: false,
        });
      }

      if (data.pocetLidi > data.lidi.length) {
        const repeat = data.pocetLidi - data.lidi.length;

        for (let i = 0; i < repeat; i++) {
          data.lidi.push({
            jmeno: "",
            prijmeni: "",
            telefon: undefined,
            email: undefined,
            ulice: undefined,
            obec: "",
            psc: "",
            typBillId: "",
            cena: "",
            cislo: undefined,
          });
        }

        data.nekdoJiny = {};

        this.setState({
          data,
          generateJa: data.pocetLidi,
          generateNekdoJiny: false,
        });
      }
    }

    if (this.state.data.organizator === "někdo jiný") {
      const data = { ...this.state.data };

      const nekdoJiny = {
        ico: data.nekdoJiny && data.nekdoJiny.ico ? data.nekdoJiny.ico : "",
        cislo:
          data.nekdoJiny && data.nekdoJiny.cislo ? data.nekdoJiny.cislo : "",
        cena: data.nekdoJiny && data.nekdoJiny.cena ? data.nekdoJiny.cena : "",
        cisloUctu:
          data.nekdoJiny && data.nekdoJiny.cisloUctu
            ? data.nekdoJiny.cisloUctu
            : "",
        jmeno:
          data.nekdoJiny && data.nekdoJiny.jmeno ? data.nekdoJiny.jmeno : "",
        prijmeni:
          data.nekdoJiny && data.nekdoJiny.prijmeni
            ? data.nekdoJiny.prijmeni
            : "",
        ulice:
          data.nekdoJiny && data.nekdoJiny.ulice ? data.nekdoJiny.ulice : "",
        obec: data.nekdoJiny && data.nekdoJiny.obec ? data.nekdoJiny.obec : "",
        psc: data.nekdoJiny && data.nekdoJiny.psc ? data.nekdoJiny.psc : "",
      };

      data.nekdoJiny = nekdoJiny;
      data.lidi = [];

      this.setState({ data, generateNekdoJiny: true, generateJa: false });
    }
  };

  generateLidiForms = (number) => {
    const array = [];
    for (let i = 0; i < number; i++) {
      array.push(
        <Fragment key={i}>
          <div className="bg-light p-2 my-2">
            <div className="row">
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorInput(`jmeno`, "Jméno", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorInput(`prijmeni`, "Příjmení", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorInput(`ulice`, "Ulice", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorInput(`obec`, "Obec", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorInput(`psc`, "PSC", i)}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorInput(`email`, "Email", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorInput(`telefon`, "Telefon", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorSelect(
                  `typBillId`,
                  "Typ vyúčtování",
                  this.state.billTypes,
                  i
                )}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorInput(`cislo`, "Číslo", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderLectorInput(`cena`, "Cena", i, "number")}
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return array;
  };

  generateFakturaForms = () => {
    return (
      <div className="bg-light p-2 mt-2">
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            {this.renderFakturaInput("jmeno", "Jméno")}
          </div>
          <div className="col-lg-2 col-sm-12">
            {this.renderFakturaInput("prijmeni", "Příjmení")}
          </div>
          <div className="col-lg-2 col-sm-12">
            {this.renderFakturaInput("obec", "Obec")}
          </div>
          <div className="col-lg-2 col-sm-12">
            {this.renderFakturaInput("ulice", "Ulice")}
          </div>
          <div className="col-lg-2 col-sm-12">
            {this.renderFakturaInput("psc", "PSC")}
          </div>
          <div className="col-lg-2 col-sm-12">
            {this.renderFakturaInput("ico", "IČO")}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            {this.renderFakturaInput("cena", "Cena")}
          </div>
          <div className="col-lg-2 col-sm-12">
            {this.renderFakturaInput("cislo", "Číslo")}
          </div>
          <div className="col-lg-2 col-sm-12">
            {this.renderFakturaInput("cisloUctu", "Číslo účtu")}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-4">
              {this.renderDatePicker("datum", "Datum")}
            </div>
            <div className="col-4">{this.renderTimePicker("od", "Od")}</div>
            <div className="col-4">{this.renderTimePicker("do", "Do")}</div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-sm-12">
              {this.renderSelect("typ", "Typ skupiny", this.state.lectorTypes)}
            </div>
            <div className="col-lg-4 col-sm-12">
              {this.renderSelect("organizator", "Organizátor", [
                { _id: "já", name: "já" },
                { _id: "někdo jiný", name: "někdo jiný" },
              ])}
            </div>
            <div className="col-lg-4 col-sm-12">
              {this.renderInput("pocetLidi", "Počet lidí", "number")}
              <button
                type="button"
                onClick={this.handleGenerateForms}
                className="btn btn-warning"
              >
                Generovat
              </button>
            </div>
          </div>
          {this.state.generateJa && (
            <div>{this.generateLidiForms(this.state.generateJa)}</div>
          )}

          {this.state.generateNekdoJiny && (
            <div>{this.generateFakturaForms()}</div>
          )}

          {this.renderButton("Uložit")}
        </form>
      </React.Fragment>
    );
  }
}

export default LectorForm;
