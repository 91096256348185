import React, { Component, Fragment } from "react";

import logo from "../assets/logo.png";

class Homepage extends Component {
  render() {
    return (
      <Fragment>
        <div className="treatmentForm">
          <div className="row mb-2">
            <div className="col-12 text-center">
              <h1 className="page-title">Leona Zavadilová</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <a href="https://leonazavadilova.cz/">
                <img src={logo} className="img-cover" alt="leonazavadilová" />
              </a>
              <p className="small normal-font">
                Po kliknutí na logo budete přesměrování na hlavní stránky
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Homepage;
