import moment from "moment";
import _ from "lodash";

const months = [
  "leden",
  "únor",
  "březen",
  "duben",
  "květen",
  "červen",
  "červenec",
  "srpen",
  "září",
  "říjen",
  "listopad",
  "prosinec",
];

function filterYear(data, chosenYear) {
  if (chosenYear) {
    return data.filter((item) => moment(item.datum).year() <= chosenYear);
  }

  return data;
}

function filterMonth(data, chosenMonth) {
  if (chosenMonth) {
    return data.filter((item) => {
      const monthIndex = moment(item.datum).month();
      const chosenMonthIndex = months.indexOf(chosenMonth);

      return monthIndex <= chosenMonthIndex;
    });
  }
  return data;
}

function filterDay(data, chosenDay, chosenMonth) {
  if (chosenDay) {
    return data.filter((item) => {
      const monthIndex = moment(item.datum).month();
      const chosenMonthIndex = months.indexOf(chosenMonth);

      const day = moment(item.datum).date();

      return !(monthIndex === chosenMonthIndex && day > chosenDay);
    });
  }

  return data;
}

export function filterTotalStats(data, chosenYear, chosenMonth, chosenDay) {
  data = filterYear(data, chosenYear);

  data = filterMonth(data, chosenMonth);

  data = filterDay(data, chosenDay, chosenMonth);

  return data;
}

function filterCertainYear(data, chosenYear) {
  if (chosenYear) {
    return data.filter((item) => moment(item.datum).year() === chosenYear);
  }

  return data;
}

function filterCertainMonth(data, chosenMonth) {
  if (chosenMonth) {
    return data.filter((item) => {
      const monthIndex = moment(item.datum).month();
      const chosenMonthIndex = months.indexOf(chosenMonth);

      return monthIndex === chosenMonthIndex;
    });
  }
  return data;
}

function filterCertainDay(data, chosenDay, chosenMonth) {
  if (chosenDay) {
    return data.filter((item) => {
      const monthIndex = moment(item.datum).month();
      const chosenMonthIndex = months.indexOf(chosenMonth);

      const day = moment(item.datum).date();

      return monthIndex === chosenMonthIndex && day === chosenDay;
    });
  }

  return data;
}

export function filterCertainStats(data, chosenYear, chosenMonth, chosenDay) {
  data = filterCertainYear(data, chosenYear);

  data = filterCertainMonth(data, chosenMonth);

  data = filterCertainDay(data, chosenDay, chosenMonth);

  return data;
}

export function getTableStuff(data, nested1, nested2) {
  const types = [];
  data.forEach((item) => {
    types.push(item[nested1][nested2]);
  });

  const result = types.reduce((prev, cur) => {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});

  const entries = Object.entries(result);
  return _.orderBy(
    entries.map(([typ, amount]) => ({ typ, amount })),
    ["amount"],
    "desc"
  );
}
