import http from "./httpService";
import jwtDecode from "jwt-decode";

const tokenKey = "token";

http.setJwt(getJwt()); // getting rid off bi-diretional dependencies (accessing auth in http and vice versa)

export async function login(email, heslo) {
  const { data: jwt } = await http.post(`/auth`, { email, heslo });
  localStorage.setItem(tokenKey, jwt);
}

export function getCurrentUser() {
  try {
    const token = localStorage.getItem(tokenKey);
    return jwtDecode(token);
  } catch (e) {}
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}
