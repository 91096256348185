import React, { Component } from "react";
import Input from "./input";
import Joi from "joi-browser";
import Select from "./select";
import TextArea from "./textArea";
import { TimePicker } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/cs";

moment.locale("cs");

class Form extends Component {
  state = { data: {}, errors: {} };

  validate = () => {
    const { error } = Joi.validate(this.state.data, this.schema, {
      abortEarly: false,
    });

    if (!error) return null;

    const errors = {};

    error.details.forEach((e) => {
      errors[e.path[0]] = e.message;
    });

    return errors;
  };

  validateProperty = (property) => {
    const { name, value } = property;

    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };

    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = (event) => {
    const { name, value } = event.currentTarget;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(event.currentTarget);
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];

    const data = { ...this.state.data };
    data[name] = value;

    this.setState({ data, errors });
  };

  handleDatePickerChange = (date, value) => {
    const data = { ...this.state.data };
    data[value] = moment(date)
      .set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
      .format();

    this.setState({ data });
  };

  handleTimePickerChange = (time, value) => {
    const data = { ...this.state.data };
    data[value] = moment(time).set({ second: 0, millisecond: 0 }).format();

    this.setState({ data });
  };

  renderButton(label) {
    return (
      <button className="btn btn-primary" disabled={this.validate()}>
        {label}
      </button>
    );
  }

  renderInput(name, label, type = "text") {
    return (
      <Input
        name={name}
        value={this.state.data[name]}
        label={label}
        onChange={this.handleChange}
        type={type}
        error={this.state.errors[name]}
      />
    );
  }

  renderSelect(name, label, options) {
    return (
      <Select
        name={name}
        value={this.state.data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={this.state.errors[name]}
      />
    );
  }

  renderTextArea(name, label) {
    return (
      <TextArea
        name={name}
        value={this.state.data[name]}
        label={label}
        onChange={this.handleChange}
        error={this.state.errors[name]}
      />
    );
  }

  renderDatePicker(name, label) {
    const error = this.state.errors[name];

    return (
      <div className="form-group">
        <label className="normal-font" htmlFor={name}>
          {label}
        </label>

        <div>
          <DatePicker
            value={this.state.data[name] || Date.now()}
            name={name}
            onChange={(date) => this.handleDatePickerChange(date, name)}
          />
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  }

  renderTimePicker(name, label) {
    const error = this.state.errors[name];

    return (
      <div className="form-group">
        <label className="normal-font" htmlFor={name}>
          {label}
        </label>

        <div>
          <TimePicker
            onChange={(time) => this.handleTimePickerChange(time, name)}
            value={
              this.state.data[name] ||
              moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            }
            ampm={false}
          />
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  }

  joiCzechMessages = (errors) => {
    return errors.forEach((err) => {
      switch (err.type) {
        case "any.empty":
          err.message = "Toto pole nesmí být prázdné!";
          break;
        case "string.min":
          err.message = `Pole musí mít nejméně ${err.context.limit} znaků!`;
          break;
        case "string.max":
          err.message = `Pole může mít maximálně ${err.context.limit} znaků!`;
          break;
        case "string.email":
          err.message = `Email musí být ve správném formátu!`;
          break;
        case "number.base":
          err.message = `Pole musí být číslo!`;
          break;
        case "number.min":
          err.message = `Číslo musí větší než ${err.context.limit}!`;
          break;
        default:
          break;
      }
    });
  };
}

export default Form;
