import React, { Component } from "react";

class Input extends Component {
  render() {
    const { name, label, type, value, onChange, error } = this.props;
    return (
      <div className="form-group">
        <label className="normal-font" htmlFor={name}>
          {label}
        </label>
        <input
          value={value}
          onChange={onChange}
          type={type}
          name={name}
          id={name}
          className="form-control input-style"
        />
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  }
}

export default Input;
