import React, { Component, Fragment } from "react";

import { getPacients } from "../services/pacientsService";
import { getTreatments } from "../services/treatmentService";
import { getGroups } from "../services/groupService";
import { getLectors } from "../services/lectorsService";

import {
  filterTotalStats,
  filterCertainStats,
  getTableStuff,
} from "../utils/statsFilter";

import _ from "lodash";
import moment from "moment";
import "moment/locale/cs";
moment.locale("cs");

class Statistics extends Component {
  state = {
    pacients: [],
    treatments: [],
    groups: [],
    lectors: [],
    chosenYear: false,
    displayYear: true,
    chosenMonth: false,
    chosenDay: false,
  };

  async componentDidMount() {
    this.setState({
      pacients: await getPacients(),
      treatments: await getTreatments(),
      groups: await getGroups(),
      lectors: await getLectors(),
    });
  }

  renderCell = (color, label, data) => {
    return (
      <div className="col-md-3 col-sm-12">
        <div className="stats-box mb-3">
          <div className="stats-box-header text-center">
            <h5 className="page-title font-weight-bold m-0">{label}</h5>
          </div>
          <div className={`card-body text-${color} text-center`}>
            <h3 className="card-title m-0">{data}</h3>
          </div>
        </div>
      </div>
    );
  };

  renderCellTable = (color, label, data) => {
    return (
      <div className="col-md-4 col-sm-12">
        <div className="stats-box mb-3">
          <div className="stats-box-header text-center">
            <h5 className="page-title font-weight-bold m-0">{label}</h5>
          </div>
          <div className={`card-body text-${color} text-center`}>
            <table className="table table-bordered bg-light">
              <tbody>
                {data.map((index) => (
                  <tr key={index.typ}>
                    <td>{index.typ}</td>
                    <td>{index.amount}</td>
                    {index.secAmount ? <td>{index.secAmount}</td> : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  getAllYears = () => {
    const { pacients, treatments, groups, lectors } = this.state;
    const all = [...pacients, ...treatments, ...groups, ...lectors];

    const years = [];

    for (let item of all) {
      years.push(moment(item.datum).year());
    }

    return _.orderBy(_.uniq(years));
  };

  handleYearReset = () => {
    this.setState({
      displayYear: true,
      chosenMonth: false,
      chosenDay: false,
    });
  };

  handleChangeYear = (year) => {
    this.setState({
      displayYear: true,
      chosenYear: year,
      chosenMonth: false,
      chosenDay: false,
    });
  };

  handleChangeMonth = (month) => {
    this.setState({
      displayYear: false,
      chosenMonth: month,
      chosenDay: false,
    });
  };

  handleChangeDay = (day) => {
    this.setState({
      displayYear: false,
      chosenDay: day,
    });
  };

  renderColor = (chosen) => {
    return chosen ? "dark" : "mama";
  };

  // stats generators
  getTotalPacients = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const pacients = [...this.state.pacients];

    let filtered = pacients;
    filtered = filterTotalStats(filtered, chosenYear, chosenMonth, chosenDay);

    const men = filtered.filter((item) => item.pohlavi === "muž");
    const women = filtered.filter((item) => item.pohlavi === "žena");

    return {
      totalPacients: filtered.length,
      totalMen: men.length,
      totalWomen: women.length,
    };
  };

  getTotalEventsData = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const treatments = [...this.state.treatments];
    const groups = [...this.state.groups];
    const lectors = [...this.state.lectors];

    const allEvents = [...treatments, ...groups, ...lectors];

    let filtered = allEvents;
    filtered = filterTotalStats(filtered, chosenYear, chosenMonth, chosenDay);

    let money = 0;
    filtered.forEach((item) => {
      if (item.vyuctovani) {
        money += item.vyuctovani.cena;
      }
      if (item.nekdoJiny && Object.keys(item.nekdoJiny).length > 0) {
        money += item.nekdoJiny.cena;
      }
      if (item.lidi && item.lidi.length > 0) {
        item.lidi.forEach((clovek) => {
          money += clovek.cena;
        });
      }
    });

    let minutes = 0;
    filtered.forEach((item) => {
      minutes += moment(item.cas.do).diff(moment(item.cas.od), "minutes");
    });

    const wage = Math.round((money / (minutes / 60)) * 10) / 10;

    return {
      totalEvents: filtered.length,
      totalMoney: money,
      totalMinutes: minutes,
      totalWage: wage || 0,
    };
  };

  getCertainTreatmentsData = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const treatments = [...this.state.treatments];

    let filtered = treatments;

    filtered = filterCertainStats(filtered, chosenYear, chosenMonth, chosenDay);

    let money = 0;
    filtered.forEach((item) => {
      money += item.vyuctovani.cena;
    });

    const men = filtered.filter((item) => item.pacient.pohlavi === "muž");
    const women = filtered.filter((item) => item.pacient.pohlavi === "žena");

    return {
      certainTreatments: filtered.length,
      certainTreatmentsMoney: money,
      certainMen: men.length,
      certainWomen: women.length,
    };
  };

  getCertainPacients = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const pacients = [...this.state.pacients];

    let filtered = pacients;

    filtered = filterCertainStats(filtered, chosenYear, chosenMonth, chosenDay);

    return filtered.length;
  };

  getCertainGroupsData = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const groups = [...this.state.groups];

    let filtered = groups;

    filtered = filterCertainStats(filtered, chosenYear, chosenMonth, chosenDay);

    let people = 0;
    filtered.forEach((item) => {
      people += item.lidi.length;
    });

    const avg = Math.round((people / filtered.length) * 10) / 10;

    let money = 0;
    filtered.forEach((item) => {
      item.lidi.forEach((clovek) => {
        money += clovek.cena;
      });
    });

    return {
      certainGroups: filtered.length,
      certainGroupsPeole: people,
      certainGroupsAvg: avg || 0,
      certainGroupsMoney: money,
    };
  };

  getCertainLectorsData = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const lectors = [...this.state.lectors];

    let filtered = lectors;

    filtered = filterCertainStats(filtered, chosenYear, chosenMonth, chosenDay);

    let people = 0;
    filtered.forEach((item) => {
      people += item.pocetLidi;
    });

    const avg = Math.round((people / filtered.length) * 10) / 10;

    let money = 0;
    filtered.forEach((item) => {
      if (item.nekdoJiny && Object.keys(item.nekdoJiny).length > 0) {
        money += item.nekdoJiny.cena;
      }

      if (item.lidi.length > 0) {
        item.lidi.forEach((clovek) => {
          money += clovek.cena;
        });
      }
    });

    const nekdoJiny = filtered.filter(
      (item) => item.organizator === "někdo jiný"
    );
    const ja = filtered.filter((item) => item.organizator === "já");

    return {
      certainLectors: filtered.length,
      certainLectorsPeople: people,
      certainLectorsAvg: avg || 0,
      certainLectorsMoney: money,
      certainLectorsJa: ja.length,
      certainLectorsNekdoJiny: nekdoJiny.length,
    };
  };

  // stats tables generator
  getBillsData = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const treatments = [...this.state.treatments];
    const groups = [...this.state.groups];
    const lectors = [...this.state.lectors];

    const allEvents = [...treatments, ...groups, ...lectors];

    let filtered = allEvents;
    filtered = filterCertainStats(filtered, chosenYear, chosenMonth, chosenDay);

    let doklad = 0;
    let faktura = 0;
    let rodina = 0;

    filtered.forEach((item) => {
      if (item.vyuctovani) {
        doklad += item.vyuctovani.typBill.nazev === "Doklad" ? 1 : 0;
        faktura += item.vyuctovani.typBill.nazev === "Faktura" ? 1 : 0;
        rodina += item.vyuctovani.typBill.nazev === "Rodina" ? 1 : 0;
      }
      if (item.nekdoJiny && Object.keys(item.nekdoJiny).length > 0) {
        faktura++;
      }
      if (item.lidi && item.lidi.length > 0) {
        item.lidi.forEach((clovek) => {
          doklad += clovek.typBillId.nazev === "Doklad" ? 1 : 0;
          faktura += clovek.typBillId.nazev === "Faktura" ? 1 : 0;
          rodina += clovek.typBillId.nazev === "Rodina" ? 1 : 0;
        });
      }
    });

    return _.orderBy(
      [
        { typ: "Doklad", amount: doklad },
        { typ: "Faktura", amount: faktura },
        { typ: "Rodina", amount: rodina },
      ],
      ["amount"],
      "desc"
    );
  };

  getTreatmentsData = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const treatments = [...this.state.treatments];

    let filtered = treatments;
    filtered = filterCertainStats(filtered, chosenYear, chosenMonth, chosenDay);

    return getTableStuff(filtered, "typ", "nazev");
  };

  getGroupsData = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const groups = [...this.state.groups];

    let filtered = groups;
    filtered = filterCertainStats(filtered, chosenYear, chosenMonth, chosenDay);

    return getTableStuff(filtered, "typ", "nazev");
  };

  getLectorsData = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const lectors = [...this.state.lectors];

    let filtered = lectors;
    filtered = filterCertainStats(filtered, chosenYear, chosenMonth, chosenDay);

    return getTableStuff(filtered, "typ", "nazev");
  };

  getPacientsData = () => {
    const { chosenYear, chosenMonth, chosenDay } = this.state;
    const treatments = [...this.state.treatments];

    let filtered = treatments;
    filtered = filterCertainStats(filtered, chosenYear, chosenMonth, chosenDay);

    filtered.forEach((item) => {
      item.pacient.fullName = `${item.pacient.jmeno} ${item.pacient.prijmeni}`;
      return item;
    });

    return getTableStuff(filtered, "pacient", "fullName");
  };

  render() {
    const { chosenYear, chosenMonth, chosenDay, displayYear } = this.state;

    const years = this.getAllYears();

    const { totalPacients, totalMen, totalWomen } = this.getTotalPacients();
    const { totalEvents, totalMoney, totalMinutes, totalWage } =
      this.getTotalEventsData();

    const {
      certainTreatments,
      certainTreatmentsMoney,
      certainMen,
      certainWomen,
    } = this.getCertainTreatmentsData();
    const certainPacients = this.getCertainPacients();

    const {
      certainGroups,
      certainGroupsPeole,
      certainGroupsAvg,
      certainGroupsMoney,
    } = this.getCertainGroupsData();

    const {
      certainLectors,
      certainLectorsPeople,
      certainLectorsAvg,
      certainLectorsMoney,
      certainLectorsJa,
      certainLectorsNekdoJiny,
    } = this.getCertainLectorsData();

    const bills = this.getBillsData();
    const treatments = this.getTreatmentsData();
    const groups = this.getGroupsData();
    const lectors = this.getLectorsData();
    const pacients = this.getPacientsData();

    return years.length > 0 ? (
      <Fragment>
        <div className="row">
          <div className="col-lg-2 col-sm-12 border-right">
            <nav className="col-md-3 col-lg-2 d-md-block sidebar">
              <div className="sidebar-sticky">
                <ul className="nav flex-column">
                  <li>
                    <button
                      className={`btn btn-${this.renderColor(
                        !chosenYear
                      )} my-2`}
                      onClick={() => this.handleChangeYear(false)}
                    >
                      <h4 className="m-0 normal-font">Celkem</h4>
                    </button>
                  </li>
                  {years.map((y) => (
                    <li key={y}>
                      <button
                        className={`btn btn-${this.renderColor(
                          chosenYear === y
                        )} my-2`}
                        onClick={() => this.handleChangeYear(y)}
                      >
                        <h4 className="m-0 normal-font">{y}</h4>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
          <div className="col-lg-10 col-sm-12">
            <nav className="mb-3 pb-3 border-bottom">
              <ul className="nav flex-row">
                {/* Year */}
                <li>
                  <button
                    className={`btn btn-${this.renderColor(displayYear)} m-2`}
                    onClick={() => this.handleYearReset()}
                  >
                    <h4 className="m-0 normal-font">Rok</h4>
                  </button>
                </li>
                {/* Month */}
                {chosenYear && (
                  <li className="nav-item dropdown">
                    <button
                      className={`btn btn-${this.renderColor(chosenMonth)} m-2`}
                      id="monthDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <h4 className="m-0 normal-font">
                        {chosenMonth ? chosenMonth : "Měsíc"}
                      </h4>
                    </button>
                    <div
                      className="dropdown-menu scrollableMenu"
                      aria-labelledby="monthDropdown"
                    >
                      {Array.from({ length: 12 }, (e, i) => {
                        return new Date(null, i + 1, null).toLocaleDateString(
                          "cs",
                          { month: "long" }
                        );
                      }).map((month) => (
                        <button
                          key={month}
                          className="dropdown-item"
                          onClick={() => this.handleChangeMonth(month)}
                        >
                          {month}
                        </button>
                      ))}
                    </div>
                  </li>
                )}
                {/* Day */}
                {chosenMonth && (
                  <li className="nav-item dropdown">
                    <button
                      className={`btn btn-${this.renderColor(chosenDay)} m-2`}
                      id="dayDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <h4 className="m-0 normal-font">
                        {chosenDay ? chosenDay : "Den"}
                      </h4>
                    </button>
                    <div
                      className="dropdown-menu scrollableMenu"
                      aria-labelledby="dayDropdown"
                      role="menu"
                    >
                      {[...Array(moment().daysInMonth()).keys()].map((day) => (
                        <button
                          key={day + 1}
                          className="dropdown-item"
                          onClick={() => this.handleChangeDay(day + 1)}
                        >
                          {day + 1}
                        </button>
                      ))}
                    </div>
                  </li>
                )}
              </ul>
            </nav>
            <div className="row">
              {this.renderCell("muted", "Celkem pacientů", totalPacients)}
              {this.renderCell(
                "muted",
                "Celkem mužů/žen",
                `${totalMen}/${totalWomen}`
              )}
              {this.renderCell("muted", "Celkem akcí", totalEvents)}
              {this.renderCell("muted", "Celkem peněz", `${totalMoney} Kč`)}
              {this.renderCell("muted", "Celkem minut", totalMinutes)}
              {this.renderCell(
                "muted",
                "Celkem mzda za hodinu",
                `${totalWage} Kč`
              )}
            </div>
            <hr />
            <div className="row">
              {this.renderCell("yellow", "Počet terapií", certainTreatments)}
              {this.renderCell(
                "yellow",
                "Počet ošetřených mužů/žen",
                `${certainMen}/${certainWomen}`
              )}
              {this.renderCell(
                "yellow",
                "Počet nových pacientů",
                certainPacients
              )}
              {this.renderCell(
                "yellow",
                "Vyděláno",
                `${certainTreatmentsMoney} Kč`
              )}
            </div>
            <hr />
            <div className="row">
              {this.renderCell("purple", "Počet skupin", certainGroups)}
              {this.renderCell("purple", "Počet lidí", certainGroupsPeole)}
              {this.renderCell("purple", "Průměrně lidí", certainGroupsAvg)}
              {this.renderCell(
                "purple",
                "Vyděláno",
                `${certainGroupsMoney} Kč`
              )}
            </div>
            <hr />
            <div className="row">
              {this.renderCell("blue", "Počet lektorství", certainLectors)}
              {this.renderCell("blue", "Počet lidí", certainLectorsPeople)}
              {this.renderCell("blue", "Průměrně lidí", certainLectorsAvg)}
              {this.renderCell("blue", "Vyděláno", `${certainLectorsMoney} Kč`)}
              {this.renderCell(
                "blue",
                "Organizátor já/někdo jiný",
                `${certainLectorsJa}/${certainLectorsNekdoJiny}`
              )}
            </div>
            <hr />
            <div className="row">
              {this.renderCellTable("secondary", "Vyúčtování", bills)}
              {this.renderCellTable("secondary", "Skupiny", groups)}
              {this.renderCellTable("secondary", "Lektorství", lectors)}
              {this.renderCellTable("secondary", "Terapie", treatments)}
              {this.renderCellTable(
                "secondary",
                "Pacienti",
                pacients.slice(0, 10)
              )}
            </div>
          </div>
        </div>
      </Fragment>
    ) : (
      <h1>Načítání...</h1>
    );
  }
}

export default Statistics;
