import React from "react";
import { login, getCurrentUser } from "../services/authService";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "./common/form";
import Joi from "joi-browser";

class Login extends Form {
  state = {
    data: {
      email: "",
      heslo: "",
    },
    errors: {},
  };

  doSubmit = async () => {
    try {
      await login(this.state.data.email, this.state.data.heslo);
      window.location = "/";
    } catch (e) {
      if (e.response && e.response.status === 400) {
        toast.error(e.response.data);
      }
    }
  };

  schema = {
    email: Joi.string()
      .email()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    heslo: Joi.string()
      .min(5)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
  };

  render() {
    if (getCurrentUser()) {
      return <Redirect to="/" />;
    }
    return (
      <div className="treatmentForm">
        <h1 className="page-title">Přihlášení</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              {this.renderInput("email", "Email")}
            </div>
            <div className="col-lg-6 col-sm-12">
              {this.renderInput("heslo", "Heslo", "password")}
            </div>
          </div>
          {this.renderButton("Přihlásit se")}
        </form>
      </div>
    );
  }
}

export default Login;
