import React, { Component } from "react";

class SearchBox extends Component {
  render() {
    return (
      <input
        className="form-control mb-3"
        type="text"
        placeholder={this.props.placeholder}
        aria-label="Search"
        onChange={(e) => this.props.onSearch(e.currentTarget.value)}
        value={this.props.value}
      ></input>
    );
  }
}

SearchBox.defaultProps = {
  placeholder: "Vyhledávat...",
};

export default SearchBox;
