import React, { Component } from "react";
import Table from "../common/table";
import { Link } from "react-router-dom";

class LectorTable extends Component {
  columns = [
    {
      label: "Typ",
      path: "typ.nazev",
      content: (lector) => (
        <Link
          to={`/lektorstvi/${lector._id}`}
          className="text-dark text-decoration-none"
        >
          {lector.typ.nazev}
        </Link>
      ),
    },
    {
      label: "Organizátor",
      path: "organizator",
    },
    {
      label: "Počet lidí",
      path: "pocetLidi",
    },
    {
      label: "Datum",
      path: "datum",
      additionalPath: "cas.od", // Kvůli řazení terapií postupně
    },
    {
      label: "Čas",
      path: "cas.od",
      content: (lector) => (
        <span>
          {lector.cas.od} - {lector.cas.do}
        </span>
      ),
    },
    {
      key: "edit",
      content: (lector) => (
        <button
          onClick={() => this.props.onEdit(lector)}
          className="btn btn-info btn-sm"
        >
          Editovat
        </button>
      ),
    },
    {
      key: "delete",
      content: (lector) => (
        <button
          onClick={() => this.props.onDelete(lector)}
          className="btn btn-danger btn-sm"
        >
          Smazat
        </button>
      ),
    },
  ];

  render() {
    const { data, sortColumn, onSort } = this.props;
    return (
      <div className="responsive-table-scroll">
        <Table
          data={data}
          columns={this.columns}
          sortColumn={sortColumn}
          onSort={onSort}
        />
      </div>
    );
  }
}

export default LectorTable;
