import React, { Fragment } from "react";
import Form from "./../common/form";
import Joi from "joi-browser";
import moment from "moment";

import {
  getGroupTypes,
  getGroup,
  saveGroup,
} from "../../services/groupService";
import { getBillTypes } from "../../services/treatmentService";
import { toast } from "react-toastify";

class GroupForm extends Form {
  state = {
    data: {
      typ: "",
      pocetLidi: "",
      datum: moment(Date.now())
        .set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
        .format(),
      od: "",
      do: "",
      lidi: [],
    },
    generate: false,
    groupTypes: [],
    billTypes: [],
    errors: {},
    groupErrors: {},
  };

  validateGroupElements = {
    _id: Joi.string(),
    jmeno: Joi.string()
      .min(2)
      .max(20)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    prijmeni: Joi.string()
      .min(2)
      .max(30)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    ulice: Joi.string()
      .max(60)
      .min(2)
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    obec: Joi.string()
      .min(2)
      .max(50)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    psc: Joi.string()
      .min(2)
      .max(7)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    typBillId: Joi.string()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    cena: Joi.number()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    cislo: Joi.string()
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
  };

  schema = {
    _id: Joi.string(),
    typ: Joi.string()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    pocetLidi: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    datum: Joi.date()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    od: Joi.date()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    do: Joi.date()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    lidi: Joi.array()
      .items(Joi.object().keys(this.validateGroupElements))
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
  };

  populateGroup = async () => {
    try {
      const groupId = this.props.id;
      if (groupId === "new") return;

      const group = await getGroup(groupId);
      this.setState({ data: this.mapToViewModel(group) });
    } catch (e) {
      if (e.response && e.response.status === 404)
        return this.props.history.replace("/not-found");
    }
  };

  async componentDidMount() {
    await this.populateGroup();

    const billTypes = await getBillTypes();
    const groupTypes = await getGroupTypes();

    this.setState({ billTypes, groupTypes });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ generate: false });
      await this.populateGroup();
    }
  }

  mapToViewModel = (group) => {
    return {
      _id: group._id,
      pocetLidi: group.pocetLidi,
      typ: group.typ._id,
      datum: group.datum,
      od: group.cas.od,
      do: group.cas.do,
      lidi: group.lidi,
    };
  };

  doSubmit = async () => {
    const data = { ...this.state.data };

    if (data.pocetLidi.toString() !== data.lidi.length.toString()) {
      return toast.error("Počet lidí se musí rovnat počtu vygenerovaných lidí");
    }

    for (let e in data) {
      if (data[e] === "") data[e] = undefined;
    }

    for (let e of data.lidi) {
      if (e.ulice === "") e.ulice = undefined;
      if (e.cislo) e.cislo = e.cislo.split("/")[0];
    }

    await saveGroup(data);
    toast.success("Skupina uložena");
    this.props.didSave();
  };

  handleGenerateLidiForms = () => {
    // updatovat state a určit počet lidí
    // vygenerovat lidi
    // "generate" je ve statu z toho duvodu, aby se form na lidi neupdatoval automaticky

    const { data } = this.state;

    if (data.pocetLidi <= data.lidi.length) {
      const repeat = data.lidi.length - data.pocetLidi;

      for (let i = 0; i < repeat; i++) {
        data.lidi.pop();
      }

      this.setState({ data, generate: data.pocetLidi });
    }

    if (data.pocetLidi > data.lidi.length) {
      const repeat = data.pocetLidi - data.lidi.length;

      for (let i = 0; i < repeat; i++) {
        data.lidi.push({
          jmeno: "",
          prijmeni: "",
          ulice: undefined,
          obec: "",
          psc: "",
          typBillId: "",
          cena: "",
          cislo: undefined,
        });
      }
      this.setState({ data, generate: data.pocetLidi });
    }
  };

  generateLidiForms = (number) => {
    const array = [];
    for (let i = 0; i < number; i++) {
      array.push(
        <Fragment key={i}>
          <div className="bg-light p-2 my-2">
            <div className="row">
              <div className="col-lg-2 col-sm-12">
                {this.renderGroupInput(`jmeno`, "Jméno", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderGroupInput(`prijmeni`, "Příjmení", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderGroupInput(`ulice`, "Ulice", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderGroupInput(`obec`, "Obec", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderGroupInput(`psc`, "PSC", i)}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 col-sm-12">
                {this.renderGroupSelect(
                  `typBillId`,
                  "Typ vyúčtování",
                  this.state.billTypes,
                  i
                )}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderGroupInput(`cislo`, "Číslo", i)}
              </div>
              <div className="col-lg-2 col-sm-12">
                {this.renderGroupInput(`cena`, "Cena", i, "number")}
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return array;
  };

  // custom handlers and renders because of nested "lidi" array of objects
  validateGroupProperty = (property) => {
    const { name, value } = property;

    const obj = { [name]: value };
    const schema = { [name]: this.validateGroupElements[name] };

    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  handleGroupChange = (event) => {
    const { name, value } = event.currentTarget;
    const id = event.currentTarget.getAttribute("data-position");

    const groupErrors = { ...this.state.groupErrors };
    const errorMessage = this.validateGroupProperty(event.currentTarget);
    if (errorMessage) groupErrors[name] = { id, errorMessage };
    else delete groupErrors[name];

    const data = { ...this.state.data };
    data.lidi[id][name] = value;

    this.setState({ data, groupErrors });
  };

  renderGroupInput(name, label, position, type = "text") {
    const error = this.state.groupErrors[name];

    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          value={this.state.data.lidi[position][name] || ""}
          onChange={this.handleGroupChange}
          type={type}
          name={name}
          id={name + position}
          className="form-control"
          data-position={position}
        />
        {error && error.id === position.toString() && (
          <div className="alert alert-danger">{error.errorMessage}</div>
        )}
      </div>
    );
  }

  renderGroupSelect(name, label, options, position) {
    const error = this.state.groupErrors[name];
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>

        <select
          name={name}
          id={name + position}
          className="form-control"
          value={this.state.data.lidi[position][name]}
          onChange={this.handleGroupChange}
          data-position={position}
        >
          <option value=""></option>
          {options.map((e) => (
            <option key={e._id} value={e._id}>
              {e.name || e.nazev}
            </option>
          ))}
        </select>
        {error && error.id === position.toString() && (
          <div className="alert alert-danger">{error.errorMessage}</div>
        )}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-4">
              {this.renderDatePicker("datum", "Datum")}
            </div>
            <div className="col-4">{this.renderTimePicker("od", "Od")}</div>
            <div className="col-4">{this.renderTimePicker("do", "Do")}</div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-12">
              {this.renderSelect("typ", "Typ skupiny", this.state.groupTypes)}
            </div>
            <div className="col-lg-6 col-sm-12">
              {this.renderInput("pocetLidi", "Počet lidí", "number")}
              <button
                type="button"
                onClick={this.handleGenerateLidiForms}
                className="btn btn-warning"
              >
                Generovat
              </button>
            </div>
          </div>
          {this.state.generate && (
            <div>{this.generateLidiForms(this.state.generate)}</div>
          )}
          {this.renderButton("Uložit")}
        </form>
      </React.Fragment>
    );
  }
}

export default GroupForm;
