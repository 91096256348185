import React, { Component } from "react";
import { getRange, DOTS } from "../../utils/paginate";

const MIN_PAGE = 1;
const FIXED_WIDTH = "45px";

class Pagination extends Component {
  handlePrevClick = () => {
    if (MIN_PAGE === this.props.currentPage) return;

    this.props.onPageChange(this.props.currentPage - 1);
  };

  handleNextClick = () => {
    const totalPages = Math.ceil(this.props.totalItems / this.props.pageSize);
    if (totalPages === this.props.currentPage) return;

    this.props.onPageChange(this.props.currentPage + 1);
  };

  render() {
    const totalPages = Math.ceil(this.props.totalItems / this.props.pageSize);
    if (totalPages === MIN_PAGE || totalPages === 0) return null;

    const pages = getRange(MIN_PAGE, totalPages, this.props.currentPage);

    const isPrevDisabled = MIN_PAGE === this.props.currentPage;
    const prevDisabledClassName = isPrevDisabled ? "disabled" : "";

    const isNextDisabled = totalPages === this.props.currentPage;
    const nextDisabledClassName = isNextDisabled ? "disabled" : "";

    return (
      <nav className="d-flex justify-content-center">
        <ul className="pagination flex-wrap">
          {/* previous move */}
          <li
            onClick={this.handlePrevClick}
            className={`page-item ${prevDisabledClassName}`}
          >
            <p role="button" className="page-link">
              <i className="fa fa-chevron-left" />
            </p>
          </li>

          {/* main pagination */}
          {pages.map((page, pageIndex) => {
            const isDots = page === DOTS;
            const dotsClassName = isDots ? "disabled" : "";
            const activeClassName =
              this.props.currentPage === page ? "active" : "";

            const onClick = isDots
              ? undefined
              : () => this.props.onPageChange(page);

            return (
              <li
                key={`${page}_${pageIndex}`}
                onClick={onClick}
                className={`page-item text-center ${dotsClassName} ${activeClassName}`}
                style={{ width: FIXED_WIDTH }}
              >
                <p role="button" className="page-link px-0">
                  {page}
                </p>
              </li>
            );
          })}

          {/* next move */}
          <li
            onClick={this.handleNextClick}
            className={`page-item ${nextDisabledClassName}`}
          >
            <p role="button" className="page-link">
              <i className="fa fa-chevron-right" />
            </p>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Pagination;
