import React, { Component } from "react";

class TableHeader extends Component {
  raiseSort = (path, additionalPath) => {
    const sortColumn = {
      path,
      additionalPath,
      order:
        this.props.sortColumn.path === path
          ? this.props.sortColumn.order === "asc"
            ? "desc"
            : "asc"
          : "asc",
    };

    this.props.onSort(sortColumn);
  };

  renderSortIcon = (column) => {
    const { sortColumn } = this.props;

    if (sortColumn.path !== column.path) return null;

    if (sortColumn.order === "asc")
      return <i className="fa fa-sort-asc" aria-hidden="true"></i>;

    return <i className="fa fa-sort-desc" aria-hidden="true"></i>;
  };

  render() {
    return (
      <thead>
        <tr>
          {this.props.columns.map((column) => (
            <th
              onClick={() => this.raiseSort(column.path, column.additionalPath)}
              scope="col"
              role="button"
              key={column.path || column.key}
            >
              {column.label} {column.label ? this.renderSortIcon(column) : null}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
