import React, { Component, Fragment } from "react";

import { getPacients } from "../services/pacientsService";
import { getTreatments } from "../services/treatmentService";
import { getLectors } from "../services/lectorsService";
import { getGroups } from "../services/groupService";
import { parseDate } from "../utils/parseDate";
import { renderPdf } from "../utils/renderPDF";
import InvoicePDF from "./invoicePDF";
import SearchBox from "./common/searchBox";
import Input from "./common/input";

import _ from "lodash";

class Invoices extends Component {
  state = {
    pacients: [],
    invoiceNumbers: [],
    allInvoiceObjects: [],
    formattedInvoiceObjs: [],
    givenInvoice: false,
    givenPacient: false,
    searchInvoice: "",
    searchPacient: "",
    odberatel: {
      firma: "",
      jmeno: "",
      prijmeni: "",
      ulice: "",
      obec: "",
      psc: "",
      ico: "",
      cisloUctu: "",
    },
  };

  getInvoiceStuff = async () => {
    const fakturaNazev = "Faktura";

    const treatments = await getTreatments();
    const lectors = await getLectors();
    const groups = await getGroups();

    const allInvoiceNumbers = [];
    const allInvoiceObjects = [];

    for (let i of treatments) {
      if (i.vyuctovani.typBill.nazev === fakturaNazev) {
        allInvoiceNumbers.push(i.vyuctovani.cislo);
        allInvoiceObjects.push(i);
      }
    }

    for (let i of lectors) {
      if (i.nekdoJiny && i.nekdoJiny.cislo) {
        allInvoiceNumbers.push(i.nekdoJiny.cislo);
        allInvoiceObjects.push(i);
      }

      if (i.lidi.length > 0) {
        for (let x of i.lidi) {
          if (x.typBillId.nazev === fakturaNazev) {
            allInvoiceNumbers.push(x.cislo);
            allInvoiceObjects.push({
              obj: x,
              datum: i.datum,
              typ: i.typ,
              druh: "lector",
            });
          }
        }
      }
    }

    for (let i of groups) {
      for (let x of i.lidi) {
        if (x.typBillId.nazev === fakturaNazev) {
          allInvoiceNumbers.push(x.cislo);
          allInvoiceObjects.push({
            obj: x,
            datum: i.datum,
            typ: i.typ,
            druh: "group",
          });
        }
      }
    }

    const invoiceNumbers = _.uniq(allInvoiceNumbers).sort();

    this.setState({ invoiceNumbers, allInvoiceObjects });
  };

  formatInvoiceObjects = () => {
    const allInvoiceObjects = [...this.state.allInvoiceObjects];
    const formattedInvoiceObjs = [];

    for (let obj of allInvoiceObjects) {
      if (obj.pacient) {
        formattedInvoiceObjs.push({
          _id: obj._id,
          jmeno: obj.pacient.jmeno,
          prijmeni: obj.pacient.prijmeni,
          typ: `Terapie - ${obj.typ.nazev}`,
          cislo: obj.vyuctovani.cislo,
          cena: obj.vyuctovani.cena,
          datum: obj.datum,
        });
      }

      if (obj.nekdoJiny) {
        formattedInvoiceObjs.push({
          _id: obj._id,
          jmeno: obj.nekdoJiny.jmeno,
          prijmeni: obj.nekdoJiny.prijmeni,
          typ: `Lektorská činnost - ${obj.typ.nazev}`,
          cislo: obj.nekdoJiny.cislo,
          cena: obj.nekdoJiny.cena,
          datum: obj.datum,
        });
      }

      if (obj.obj) {
        formattedInvoiceObjs.push({
          _id: obj.obj._id,
          jmeno: obj.obj.jmeno,
          prijmeni: obj.obj.prijmeni,
          typ:
            obj.druh === "lector"
              ? `Lektorská činnost - ${obj.typ.nazev}`
              : `Skupina - ${obj.typ.nazev}`,
          cislo: obj.obj.cislo,
          cena: obj.obj.cena,
          datum: obj.datum,
        });
      }
    }

    for (let obj of formattedInvoiceObjs) {
      obj.datum = parseDate(obj.datum);
    }

    this.setState({ formattedInvoiceObjs });
  };

  async componentDidMount() {
    await this.getInvoiceStuff();

    this.formatInvoiceObjects();

    this.setState({ pacients: await getPacients() });
  }

  handleSearchInvoice = (searchInvoice) => {
    this.setState({ searchInvoice });
  };

  handleSearchPacient = (searchPacient) => {
    this.setState({ searchPacient });
  };

  handleChooseInvoice = (givenInvoice) => {
    this.setState({ givenInvoice });
  };

  handleChoosePacient = (givenPacient) => {
    const odberatel = { ...this.state.odberatel };

    const newOdberatel = {
      firma: odberatel.firma,
      jmeno: givenPacient.jmeno || odberatel.jmeno,
      prijmeni: givenPacient.prijmeni || odberatel.prijmeni,
      ulice: givenPacient.adresa.ulice || odberatel.ulice,
      obec: givenPacient.adresa.obec || odberatel.obec,
      psc: givenPacient.adresa.psc || odberatel.psc,
      ico: givenPacient.ico || odberatel.ico,
      cisloUctu: givenPacient.cisloUctu || odberatel.cisloUctu,
    };

    this.setState({ givenPacient, odberatel: newOdberatel });
  };

  handleOdberatelInputChange = (e) => {
    const { name, value } = e.currentTarget;
    const odberatel = { ...this.state.odberatel };

    odberatel[name] = value;

    this.setState({ odberatel });
  };

  renderGivenInvoices = (invoice) => {
    const data = this.getInvoicesToPDF(invoice);

    return (
      <Fragment>
        <div className="treatmentForm">
          <h4 className="conforta-font">Vybraná faktura: {invoice}</h4>
          <div className="responsive-table-scroll">
            <table className="table table-bordered bg-light mb-0">
              <tbody className="invoices-table">
                {data.map((f) => (
                  <tr key={f._id}>
                    <td>
                      {f.jmeno} {f.prijmeni}
                    </td>
                    <td>{f.typ}</td>
                    <td>{f.datum}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  };

  getInvoicesToPDF = (invoice) => {
    const formatted = [...this.state.formattedInvoiceObjs];
    return _.orderBy(
      formatted.filter((f) => f.cislo === invoice),
      ["datum"],
      "asc"
    );
  };

  getData = () => {
    const { searchInvoice, searchPacient } = this.state;

    let invoiceNumbers = [...this.state.invoiceNumbers];
    let pacients = [...this.state.pacients];

    if (searchInvoice) {
      invoiceNumbers = invoiceNumbers.filter((e) => e.includes(searchInvoice));
    }

    if (searchPacient) {
      pacients = pacients.filter((p) => {
        const filterString = `${p.jmeno} ${p.prijmeni}`;
        return filterString.toLowerCase().includes(searchPacient.toLowerCase());
      });
    }

    return { invoiceNumbers, pacients };
  };

  renderOdberatelInput(name, label) {
    const odberatel = { ...this.state.odberatel };

    return (
      <Input
        name={name}
        label={label}
        type="text"
        value={odberatel[name]}
        onChange={(e) => this.handleOdberatelInputChange(e)}
        error={undefined}
      />
    );
  }

  render() {
    const {
      givenInvoice,
      searchInvoice,
      searchPacient,
      odberatel,
      formattedInvoiceObjs,
    } = this.state;

    const { invoiceNumbers, pacients } = this.getData();

    return formattedInvoiceObjs.length > 0 && pacients.length > 0 ? (
      <Fragment>
        <div className="row">
          <div className="col-6">
            <h1 className="page-title">Faktury</h1>
          </div>
          <div className="col-6 text-right">
            {givenInvoice &&
              odberatel.jmeno &&
              odberatel.prijmeni &&
              odberatel.ico &&
              odberatel.cisloUctu &&
              odberatel.obec &&
              odberatel.psc &&
              renderPdf(
                <InvoicePDF
                  data={this.getInvoicesToPDF(givenInvoice)}
                  odberatel={odberatel}
                  faktura={givenInvoice}
                />,
                `Faktura - ${givenInvoice}`
              )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <SearchBox
              placeholder="Vyhledávat pacienta..."
              value={searchPacient}
              onSearch={this.handleSearchPacient}
            />
            <div className="invoice-box mb-2">
              <table className="table table-bordered table-striped mb-0">
                <tbody>
                  {pacients.map((p) => (
                    <tr
                      key={p._id}
                      onClick={() => this.handleChoosePacient(p)}
                      role="button"
                    >
                      <td>
                        {p.jmeno} {p.prijmeni}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <SearchBox
              placeholder="Vyhledávat číslo faktury..."
              value={searchInvoice}
              onSearch={this.handleSearchInvoice}
            />
            <div className="invoice-box mb-2">
              <table className="table table-bordered table-striped mb-0">
                <tbody>
                  {invoiceNumbers.map((p) => (
                    <tr
                      key={p}
                      onClick={() => this.handleChooseInvoice(p)}
                      role="button"
                    >
                      <td>{p}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-lg-6 col-sm-12">
            <div className="treatmentForm">
              <h4 className="conforta-font">Odběratel</h4>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  {this.renderOdberatelInput("jmeno", "Jméno")}
                </div>
                <div className="col-lg-6 col-sm-12">
                  {this.renderOdberatelInput("prijmeni", "Příjmení")}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-sm-12">
                  {this.renderOdberatelInput("firma", "Firma")}
                </div>
                <div className="col-lg-3 col-sm-12">
                  {this.renderOdberatelInput("ico", "IČO")}
                </div>
                <div className="col-lg-4 col-sm-12">
                  {this.renderOdberatelInput("cisloUctu", "Číslo účtu")}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-sm-12">
                  {this.renderOdberatelInput("ulice", "Ulice")}
                </div>
                <div className="col-lg-4 col-sm-12">
                  {this.renderOdberatelInput("obec", "Obec")}
                </div>
                <div className="col-lg-3 col-sm-12">
                  {this.renderOdberatelInput("psc", "PSČ")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 mt-4 mt-md-0">
            {givenInvoice && this.renderGivenInvoices(givenInvoice)}
          </div>
        </div>
      </Fragment>
    ) : (
      <h1>Načítání...</h1>
    );
  }
}

export default Invoices;
