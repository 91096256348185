import React from "react";
import { saveAs } from "file-saver";
import { BlobProvider, PDFDownloadLink, pdf } from "@react-pdf/renderer";

export function renderPdfButton(doc) {
  return (
    <BlobProvider document={doc}>
      {({ blob, url, loading }) =>
        loading ? (
          <button className="btn btn-success">Generování...</button>
        ) : (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none text-white"
          >
            <button className="btn btn-success">
              <i className="fa fa-print"></i>
            </button>
          </a>
        )
      }
    </BlobProvider>
  );
}

export function renderPdfDownloadButton(doc, filename) {
  return (
    <PDFDownloadLink document={doc} fileName={filename + ".pdf"}>
      {({ blob, url, loading }) =>
        loading ? (
          <button className="btn btn-success mx-3">Generování...</button>
        ) : (
          <button className="btn btn-success mx-3">
            <i className="fa fa-download"></i>
          </button>
        )
      }
    </PDFDownloadLink>
  );
}

async function downloadPfd(doc, filename) {
  const blob = await pdf(doc).toBlob();
  saveAs(blob, `${filename}.pdf`);
}

export function renderPdf(doc, filename) {
  return (
    <button
      className="btn btn-success"
      onClick={() => downloadPfd(doc, filename)}
    >
      <i className="fa fa-download"></i>
    </button>
  );
}
