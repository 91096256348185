import http from "./httpService";

const url = "/treatments";
const treatmentTypesUrl = "/treatment_types";
const billTypesUrl = "/bill_types";

export async function getTreatments() {
  const { data } = await http.get(url);
  return data;
}

export async function getTreatment(id) {
  const { data } = await http.get(`${url}/${id}`);
  return data;
}

export async function getPacientTreatments(pacientId) {
  const { data } = await http.get(`${url}/pacient/${pacientId}`);
  return data;
}

export async function getTreatmentTypes() {
  const { data } = await http.get(treatmentTypesUrl);
  return data;
}

export async function getBillTypes() {
  const { data } = await http.get(billTypesUrl);
  return data;
}

export function saveTreatment(treatment) {
  const data = { ...treatment };

  if (treatment._id) {
    delete data._id;
    return http.put(`${url}/${treatment._id}`, data);
  }

  return http.post(url, data);
}

export function deleteTreatment(id) {
  return http.delete(`${url}/${id}`);
}
