import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCurrentUser, logout } from "../../services/authService";

const ProtecterRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!getCurrentUser() || !getCurrentUser().isAdmin) {
          logout();
          return (
            <Redirect
              to={{ pathname: "/prihlaseni", state: { from: props.location } }}
            />
          );
        }

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtecterRoute;
