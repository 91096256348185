import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getCurrentUser, logout } from "./services/authService";
import NavBar from "./components/navbar";
import Pacients from "./components/pacients";
import Pacient from "./components/pacient";
import PacientForm from "./components/pacientForm";
import Treatments from "./components/treatments";
import Whisperers from "./components/whisperers";
import WhispererForm from "./components/whispererForm";
import Groups from "./components/groups";
import Group from "./components/groupStuff/group";
import Lectors from "./components/lectors";
import Lector from "./components/lectorStuff/lector";
import Invoices from "./components/invoices";
import Calendar from "./components/calendar";
import Statistics from "./components/statistics";
import Login from "./components/login";
import Logout from "./components/logout";
import Homepage from "./components/home";
import NotFound from "./components/not-found";
import ProtecterRoute from "./components/common/protectedRoute";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

class App extends Component {
  state = {};

  componentDidMount() {
    const user = getCurrentUser();
    if (user && user.isAdmin) {
      return this.setState({ user });
    }
    logout();
  }

  render() {
    const { user } = this.state;

    return (
      <Fragment>
        <ToastContainer />
        <NavBar user={user} />
        <main className="container">
          <Switch>
            <ProtecterRoute path="/pacienti/edit/:id" component={PacientForm} />
            <ProtecterRoute path="/pacienti/:id" component={Pacient} />
            <ProtecterRoute path="/pacienti" component={Pacients} />
            <ProtecterRoute path="/osetreni" component={Treatments} />
            <ProtecterRoute path="/naseptavac/:id" component={WhispererForm} />
            <ProtecterRoute path="/naseptavac" component={Whisperers} />
            <ProtecterRoute path="/skupiny/:id" component={Group} />
            <ProtecterRoute path="/skupiny" component={Groups} />
            <ProtecterRoute path="/lektorstvi/:id" component={Lector} />
            <ProtecterRoute path="/lektorstvi" component={Lectors} />
            <ProtecterRoute path="/faktury" component={Invoices} />
            <ProtecterRoute path="/kalendar" component={Calendar} />
            <ProtecterRoute path="/statistiky" component={Statistics} />
            <Route path="/prihlaseni" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/" component={Homepage} />
            <Redirect to="/not-found" />
          </Switch>
        </main>
      </Fragment>
    );
  }
}

export default App;
