import React from "react";
import Form from "./../common/form";
import Joi from "joi-browser";
import {
  getTreatment,
  getTreatmentTypes,
  getBillTypes,
  saveTreatment,
} from "../../services/treatmentService";
import moment from "moment";
import { getWhisperers } from "../../services/whispererService";
import { toast } from "react-toastify";

class TreatmentForm extends Form {
  state = {
    data: {
      pacientId: "",
      typId: "",
      hlavniProblem: "",
      datum: moment(Date.now())
        .set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
        .format(),
      od: "",
      do: "",
      typBillId: "",
      cena: "",
      cislo: "",
      popis: "",
    },
    treatmentTypes: [],
    billTypes: [],
    whisperers: [],
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    pacientId: Joi.string()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    typId: Joi.string()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    hlavniProblem: Joi.string()
      .min(3)
      .max(50)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    datum: Joi.date()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    od: Joi.date()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    do: Joi.date()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    typBillId: Joi.string()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    cena: Joi.number()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    cislo: Joi.string()
      .allow("")
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    popis: Joi.string()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
  };

  handleWhisperer = (text) => {
    const data = { ...this.state.data };
    data.popis = data.popis + text;

    this.setState({ data });
  };

  populateTreatment = async () => {
    try {
      const treatmentId = this.props.id;
      if (treatmentId === "new") return;

      const treatment = await getTreatment(treatmentId);
      this.setState({ data: this.mapToViewModel(treatment) });
    } catch (e) {
      if (e.response && e.response.status === 404)
        return this.props.history.replace("/not-found");
    }
  };

  async componentDidMount() {
    await this.populateTreatment();

    const data = { ...this.state.data };
    data.pacientId = this.props.match.params.id;

    const treatmentTypes = await getTreatmentTypes();
    const billTypes = await getBillTypes();
    const whisperers = await getWhisperers();

    this.setState({ data, treatmentTypes, billTypes, whisperers });
  }

  mapToViewModel = (treatment) => {
    return {
      _id: treatment._id,
      pacientId: treatment.pacient,
      typId: treatment.typ,
      hlavniProblem: treatment.hlavniProblem,
      datum: treatment.datum,
      od: treatment.cas.od,
      do: treatment.cas.do,
      typBillId: treatment.vyuctovani.typBill,
      cena: treatment.vyuctovani.cena,
      cislo: treatment.vyuctovani.cislo,
      popis: treatment.popis,
    };
  };

  doSubmit = async () => {
    const data = { ...this.state.data };
    data.cislo = data.cislo.split("/")[0];

    await saveTreatment(data);
    toast.success("Ošetření přidáno");
    this.props.history.push(`/osetreni`);
  };

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              {this.renderDatePicker("datum", "Datum")}
            </div>
            <div className="col-lg-4 col-sm-12">
              {this.renderTimePicker("od", "Od")}
            </div>
            <div className="col-lg-4 col-sm-12">
              {this.renderTimePicker("do", "Do")}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-sm-12">
              {this.renderSelect(
                "typId",
                "Typ ošetření",
                this.state.treatmentTypes
              )}
            </div>
            <div className="col-lg-8 col-sm-12">
              {this.renderInput("hlavniProblem", "Hlavní problém")}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              {this.renderSelect(
                "typBillId",
                "Typ vyúčtování",
                this.state.billTypes
              )}
            </div>
            <div className="col-lg-4 col-sm-12">
              {this.renderInput("cena", "Cena")}
            </div>
            <div className="col-lg-4 col-sm-12">
              {this.renderInput("cislo", "Číslo")}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              {this.renderTextArea("popis", "Popis terapie")}
              <div className="my-3">
                {this.state.whisperers.map((w) => (
                  <button
                    key={w._id}
                    type="button"
                    className="btn btn-light btn-sm mr-2"
                    onClick={() => this.handleWhisperer(w.text)}
                  >
                    {w.zkratka}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {this.renderButton("Uložit")}
        </form>
      </React.Fragment>
    );
  }
}

export default TreatmentForm;
