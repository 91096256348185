import React, { Component } from "react";

class Select extends Component {
  render() {
    const { name, label, value, onChange, options, error } = this.props;

    return (
      <div className="form-group">
        <label className="normal-font" htmlFor={name}>
          {label}
        </label>

        <select
          name={name}
          id={name}
          className="form-control input-style"
          value={value}
          onChange={onChange}
        >
          <option value=""></option>
          {options.map((e) => (
            <option key={e._id} value={e._id}>
              {e.name || e.nazev}
            </option>
          ))}
        </select>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  }
}

export default Select;
