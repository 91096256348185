import http from "./httpService";

const url = "/whisperers";

export async function getWhisperers() {
  const { data } = await http.get(url);
  return data;
}

export async function getWhisperer(id) {
  const { data } = await http.get(`${url}/${id}`);
  return data;
}

export function saveWhisperer(data) {
  const body = { ...data };

  if (data._id) {
    delete body._id;
    return http.put(`${url}/${data._id}`, body);
  }

  return http.post(url, body);
}

export function deleteWhisperer(id) {
  return http.delete(`${url}/${id}`);
}
