import React, { Component } from "react";

class ListGroup extends Component {
  render() {
    return (
      <ul className="list-group mb-4">
        <li
          role="button"
          className={
            this.props.selected ? "list-group-item" : "list-group-item active"
          }
          onClick={() => this.props.onItemSelect(undefined)}
        >
          {this.props.default}
        </li>
        {this.props.items.map((item) => (
          <li
            role="button"
            className={
              this.props.selected === item
                ? "list-group-item active"
                : "list-group-item"
            }
            key={item[this.props.valueProperty]}
            onClick={() => this.props.onItemSelect(item)}
          >
            {item[this.props.textProperty]}
          </li>
        ))}
      </ul>
    );
  }
}

ListGroup.defaultProps = {
  textProperty: "nazev",
  valueProperty: "_id",
};

export default ListGroup;
