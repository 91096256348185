import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { getPacients, deletePacient } from "../services/pacientsService";
import { parseDate } from "../utils/parseDate";
import { paginate } from "../utils/paginate";
import { toast } from "react-toastify";
import PacientsTable from "./pacientsTable";
import Pagination from "./common/pagination";
import _ from "lodash";
import SearchBox from "./common/searchBox";

class Pacients extends Component {
  state = {
    pacients: [],
    sortColumn: { path: "prijmeni", additionalPath: "jmeno", order: "asc" },
    currentPage: 1,
    pageSize: 10,
    searching: "",
  };

  async componentDidMount() {
    this.setState({ pacients: this.parsePacientDate(await getPacients()) });
  }

  handleEdit = (pacient) => {
    this.props.history.push(`/pacienti/edit/${pacient._id}`);
  };

  handleDelete = async (pacient) => {
    if (window.confirm("Opravdu chceš smazat tohoto pacienta?")) {
      const originalPacients = [...this.state.pacients];

      const pacients = this.state.pacients.filter((p) => p._id !== pacient._id);

      // PAGE MOVE FIX
      const sorted = _.orderBy(
        this.state.pacients,
        [this.state.sortColumn.path],
        [this.state.sortColumn.order]
      );
      if (
        _.last(sorted)._id.toString() === pacient._id.toString() &&
        pacients.length % this.state.pageSize === 0
      ) {
        this.setState({ pacients, currentPage: this.state.currentPage - 1 });
      } // ensures that pageNumber will change when last item of that page is deleted
      // /PAGE MOVE FIX

      this.setState({ pacients });

      try {
        await deletePacient(pacient._id);
        toast.success("Pacient smazán");
      } catch (e) {
        if (e.response && e.response.status === 404) {
          toast.error(e.response.data);
        }
        this.setState({ pacients: originalPacients });
      }
    }
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleSearch = (searching) => {
    this.setState({ searching, currentPage: 1 });
  };

  parsePacientDate(data) {
    return data.map((index) => {
      index.datum = parseDate(index.datum);
      return index;
    });
  }

  getPagedData = () => {
    const {
      pacients,
      sortColumn,
      currentPage,
      pageSize,
      searching,
    } = this.state;

    let filtered = pacients;

    if (searching) {
      filtered = pacients.filter((p) => {
        const filterString = `${p.jmeno} ${p.prijmeni}`;
        return filterString.toLowerCase().includes(searching.toLowerCase());
      });
    }

    const sorted = _.orderBy(
      filtered,
      [sortColumn.path, sortColumn.additionalPath],
      [sortColumn.order, sortColumn.order]
    );

    const paged = paginate(sorted, currentPage, pageSize);

    return { data: paged, totalCount: sorted.length };
  };

  render() {
    const {
      sortColumn,
      currentPage,
      pageSize,
      searching,
      pacients,
    } = this.state;

    const { data, totalCount } = this.getPagedData();

    return pacients.length > 0 ? (
      <Fragment>
        <div className="row align-items-center mb-2">
          <div className="col-6">
            <h1 className="page-title m-0">Pacienti</h1>
          </div>
          <div className="col-6 text-right">
            <Link className="btn btn-success  m-0" to="/pacienti/edit/new">
              Nový pacient
            </Link>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <SearchBox
              className="m-0"
              value={searching}
              onSearch={this.handleSearch}
            />
          </div>
          <div className="col-6 text-right">
            <p className="items-shown m-0">Zobrazeno {totalCount} pacientů</p>
          </div>
        </div>
        <PacientsTable
          data={data}
          onDelete={this.handleDelete}
          onEdit={this.handleEdit}
          onSort={this.handleSort}
          sortColumn={sortColumn}
        />
        <Pagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalItems={totalCount}
          onPageChange={this.handlePageChange}
        />
      </Fragment>
    ) : (
      <h1>Načítání...</h1>
    );
  }
}

export default Pacients;
