import React, { Component } from "react";
import { Page, Text, Document, View, Image } from "@react-pdf/renderer";
import styles from "../../utils/pdfStyles";

import { parseDate, parseTime } from "../../utils/parseDate";

import topImg from "../../assets/pacientTopImg.png";
import bodyImgMale from "../../assets/humanFigureMale.png";
import bodyImgFemale from "../../assets/humanFigureFemale.png";
import moment from "moment";

class PacientPDF extends Component {
  renderAnamnezaStuff = (property, label) => {
    return property ? (
      <Text style={styles.anamnezaStuffInfo}>
        <Text style={styles.bold}>{label}: </Text>
        {property}
      </Text>
    ) : null;
  };

  render() {
    const { pacient, treatments, treatmentTypes, billTypes } = this.props;

    return (
      <Document>
        <Page style={styles.page}>
          <View>
            <Image style={styles.image} source={topImg} />
          </View>
          <View style={styles.pacientInfoCover}>
            <Text style={styles.stuffTitle}>
              {pacient.jmeno} {pacient.prijmeni}
            </Text>
            <Text style={styles.stuffInfo}>
              {pacient.adresa.ulice ? `${pacient.adresa.ulice}, ` : null}{" "}
              {pacient.adresa.obec}, {pacient.adresa.psc}
            </Text>
            <Text style={styles.stuffInfo}>
              {pacient.telefon}, {pacient.email}
            </Text>
            <Text style={styles.stuffInfo}>{pacient.rodneCislo}</Text>
          </View>
          <View style={styles.anamnezaCover}>
            <Text style={styles.stuffTitle}>Osobní anamnéza</Text>
            <View style={styles.anamnezaStuff}>
              <View style={styles.anamnezaStuffInsideLeft}>
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(pacient.anamneza.srdce, "Srdce")}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(
                    pacient.anamneza.gynekologieAndrologie,
                    "Gynekologie/Andrologie"
                  )}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(
                    pacient.anamneza.zazivani,
                    "Zažívání"
                  )}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(pacient.anamneza.kosti, "Kosti")}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(pacient.anamneza.krev, "Krev")}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(
                    pacient.anamneza.urologie,
                    "Urologie"
                  )}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(pacient.anamneza.nadory, "Nádory")}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(pacient.anamneza.plice, "Plíce")}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(pacient.anamneza.operace, "Operace")}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(
                    pacient.anamneza.medikamenty,
                    "Medikamenty"
                  )}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(pacient.anamneza.ostatni, "Ostatní")}
                {pacient.anamneza &&
                  this.renderAnamnezaStuff(pacient.anamneza.cil, "Cíl")}
              </View>
              <View style={styles.anamnezaStuffInsideRight}>
                <Image
                  source={
                    pacient.pohlavi === "žena" ? bodyImgFemale : bodyImgMale
                  }
                ></Image>
              </View>
            </View>
          </View>
          <View style={styles.bottomFixed}>
            <View style={styles.bottomSentences}>
              <Text style={styles.anamnezaStuffInfo}>
                Můj zájem o péči fyzioterapií nebo masáž stvrzuji svým podpisem.
                Poskytl{pacient.pohlavi === "žena" ? "a" : null} jsem pravdivě
                anamnestická data. Byl{pacient.pohlavi === "žena" ? "a" : null}{" "}
                jsem seznámen{pacient.pohlavi === "žena" ? "a" : null} s
                hodinovou sazbou 1200,- Kč za požadované vstupní zdravotnické
                ošetření. Souhlasím, aby{" "}
                <Text style={styles.italic}>Fyzioterapie Leona Zavadilová</Text>{" "}
                zpracovávala tyto osobní údaje:{" "}
                <Text style={styles.italic}>
                  jméno, příjmení, rodné číslo, bydliště, telefonní číslo a
                  email
                </Text>{" "}
                ve smyslu nařízení Evropského parlamentu a Rady EU 2016/679.
              </Text>
              <Text style={styles.anamnezaStuffInfo}>
                České Budějovice........................................
              </Text>
            </View>
          </View>
        </Page>
        <Page style={styles.page} wrap>
          <View style={styles.topStuff} fixed>
            <Text fixed>
              {pacient.jmeno} {pacient.prijmeni}
            </Text>
            <Text fixed>{pacient.rodneCislo}</Text>
            <Text fixed>{pacient.telefon}</Text>
          </View>
          <View style={styles.kinezRozbor}>
            <Text style={{ ...styles.anamnezaStuffInfo, ...styles.bold }}>
              Kineziologický rozbor
            </Text>
            <Text style={styles.rozborText}>{pacient.rozbor}</Text>
          </View>

          {treatments.length > 0 &&
            treatments.map((treatment) => (
              <View key={treatment._id} style={styles.terapie}>
                <View style={styles.terapieTopTexts} break>
                  <Text>
                    Terapie -{" "}
                    {treatmentTypes.find((e) => e._id === treatment.typ).nazev}
                  </Text>
                  <Text>
                    {parseDate(treatment.datum)}{" "}
                    <Text style={styles.light}>
                      {" "}
                      {parseTime(treatment.cas.od)} -{" "}
                      {parseTime(treatment.cas.do)} (
                      {moment(treatment.cas.do).diff(
                        moment(treatment.cas.od),
                        "minutes"
                      )}{" "}
                      min.)
                    </Text>
                  </Text>
                </View>
                <Text style={styles.rozborText}>
                  <Text style={styles.bold}>Vyúčtování:</Text>{" "}
                  <Text style={styles.light}>
                    {treatment.vyuctovani.cena} Kč -{" "}
                    {
                      billTypes.find(
                        (e) => e._id === treatment.vyuctovani.typBill
                      ).nazev
                    }{" "}
                    č. {treatment.vyuctovani.cislo}
                  </Text>
                </Text>
                <Text style={styles.rozborText}>
                  <Text style={styles.bold}>Hlavní problém:</Text>{" "}
                  <Text style={styles.light}>{treatment.hlavniProblem}</Text>
                </Text>
                <Text style={styles.rozborText}>
                  <Text style={styles.bold}>Popis:</Text>
                  {"\n"}
                  <Text style={styles.light}>{treatment.popis}</Text>
                </Text>
              </View>
            ))}
        </Page>
      </Document>
    );
  }
}

export default PacientPDF;
