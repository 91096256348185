import * as Sentry from "@sentry/react";

function init() {
  // Logging service
  Sentry.init({
    dsn:
      "https://44b0fd79c10045e0b7cb7f1f93dabae6@o421896.ingest.sentry.io/5357605",
  });
}

function log(ex) {
  Sentry.captureException(ex);
}

export default { init, log };
