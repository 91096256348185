import React, { Component, Fragment } from "react";
import {
  getTreatments,
  getTreatmentTypes,
  getBillTypes,
} from "../services/treatmentService";
import { parseDate, parseTime } from "../utils/parseDate";
import { paginate } from "../utils/paginate";
import TreatmentsTable from "./treatmentsTable";
import Pagination from "./common/pagination";
import _ from "lodash";
import SearchBox from "./common/searchBox";
import ListGroup from "./common/listGroup";

class Treatments extends Component {
  state = {
    treatments: [],
    treatmentTypes: [],
    billTypes: [],
    selectedTreatmentType: undefined,
    selectedBillType: undefined,
    sortColumn: { path: "datum", additionalPath: "cas.od", order: "desc" },
    currentPage: 1,
    pageSize: 15,
    searchPacient: "",
    searchDate: "",
  };

  async componentDidMount() {
    this.setState({
      treatments: this.parseTreatmentDate(await getTreatments()),
      treatmentTypes: await getTreatmentTypes(),
      billTypes: await getBillTypes(),
    });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearchPacient = (searchQuery) => {
    this.setState({
      searchPacient: searchQuery,
      currentPage: 1,
    });
  };

  handleSearchDate = (searchQuery) => {
    this.setState({
      searchDate: searchQuery,
      currentPage: 1,
    });
  };

  handleFilterTreatmentChange = (selected) => {
    this.setState({
      selectedTreatmentType: selected,
      currentPage: 1,
    });
  };

  handleFilterBillChange = (selected) => {
    this.setState({
      selectedBillType: selected,
      currentPage: 1,
    });
  };

  parseTreatmentDate(data) {
    return data.map((index) => {
      index.datum = parseDate(index.datum);
      index.cas.od = parseTime(index.cas.od);
      index.cas.do = parseTime(index.cas.do);
      return index;
    });
  }

  getData() {
    const {
      treatments,
      sortColumn,
      currentPage,
      pageSize,
      searchPacient,
      searchDate,
      selectedTreatmentType,
      selectedBillType,
    } = this.state;

    let filtered = treatments;

    if (searchPacient) {
      filtered = treatments.filter((treatment) => {
        const filterString = `${treatment.pacient.jmeno} ${treatment.pacient.prijmeni}`;
        return filterString.toLowerCase().includes(searchPacient.toLowerCase());
      });
    }

    if (searchDate) {
      filtered = filtered.filter((treatment) =>
        treatment.datum.includes(searchDate)
      );
    }

    if (selectedTreatmentType) {
      filtered = filtered.filter(
        (treatment) =>
          treatment.typ._id.toString() === selectedTreatmentType._id.toString()
      );
    }

    if (selectedBillType) {
      filtered = filtered.filter(
        (treatment) =>
          treatment.vyuctovani.typBill._id.toString() ===
          selectedBillType._id.toString()
      );
    }

    const sorted = _.orderBy(
      filtered,
      [sortColumn.path, sortColumn.additionalPath],
      [sortColumn.order, sortColumn.order]
    );

    const paged = paginate(sorted, currentPage, pageSize);

    return { data: paged, totalCount: sorted.length };
  }

  render() {
    const {
      sortColumn,
      pageSize,
      currentPage,
      searchPacient,
      searchDate,
      treatmentTypes,
      billTypes,
      selectedTreatmentType,
      selectedBillType,
      treatments,
    } = this.state;
    const { data, totalCount } = this.getData();

    return treatments.length > 0 &&
      treatmentTypes.length > 0 &&
      billTypes.length > 0 ? (
      <Fragment>
        <div className="row align-items-center mb-2">
          <div className="col-6">
            <h1 className="page-title m-0">Ošetření</h1>
          </div>
          <div className="col-6 text-right">
            <p className="items-shown m-0">Zobrazeno {totalCount} ošetření</p>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-6 col-sm-12">
            <SearchBox
              value={searchPacient}
              onSearch={this.handleSearchPacient}
              placeholder="Vyhledávat pacienta..."
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <SearchBox
              value={searchDate}
              onSearch={this.handleSearchDate}
              placeholder="Vyhledávat datum..."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            <ListGroup
              default="Všechny typy"
              selected={selectedBillType}
              items={billTypes}
              onItemSelect={this.handleFilterBillChange}
            />
            <ListGroup
              default="Všechny typy"
              selected={selectedTreatmentType}
              items={treatmentTypes}
              onItemSelect={this.handleFilterTreatmentChange}
            />
          </div>
          <div className="col-lg-10 col-sm-12">
            <TreatmentsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
            />
            <Pagination
              totalItems={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </Fragment>
    ) : (
      <h1>Načítání...</h1>
    );
  }
}

export default Treatments;
