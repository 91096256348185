import React, { Component, Fragment } from "react";
import { getGroup } from "../../services/groupService";
import { getBillTypes } from "../../services/treatmentService";
import { parseTime, parseDate } from "../../utils/parseDate";
import { Link } from "react-router-dom";

class Group extends Component {
  state = {
    group: { typ: {}, lidi: [], cas: {} },
    billTypes: [],
  };

  async componentDidMount() {
    try {
      const group = this.parseGroupDate(
        await getGroup(this.props.match.params.id)
      );
      this.setState({ group, billTypes: await getBillTypes() });
    } catch (e) {
      this.props.history.replace("/not-found");
    }
  }

  parseGroupDate(data) {
    data.datum = parseDate(data.datum);
    data.cas.od = parseTime(data.cas.od);
    data.cas.do = parseTime(data.cas.do);

    return data;
  }

  getBillTypeById(_id) {
    const billType = this.state.billTypes.find((b) => b._id === _id);
    return billType.nazev;
  }

  render() {
    const { group } = this.state;

    let childPlace = 0;
    return Object.keys(group).length > 3 ? (
      <Fragment>
        <div className="row">
          <div className="col-12 text-right">
            <Link to="/skupiny" className="btn btn-secondary">
              Zpět
            </Link>
          </div>
        </div>
        <hr className="col-xs-12"></hr>
        <div className="row">
          <div className="col-12 pacient-topInfo">
            <h1 className="page-title">{group.typ.nazev}</h1>
            <h3 className="font-weight-normal normal-font">{group.datum}</h3>
            <h4 className="font-weight-normal normal-font">
              {group.cas.od} - {group.cas.do}
            </h4>
          </div>
        </div>
        <hr className="col-xs-12"></hr>
        <div className="row">
          {group.lidi.map((clovek) => {
            childPlace++;
            return (
              <div
                className={`col-lg-6 col-sm-12 p-0 ${
                  childPlace % 2 === 0 ? "pl-lg-2" : "pr-lg-2"
                }`}
                key={clovek._id}
              >
                <div className="pacient-anamneza-info mb-3">
                  <div className="card-body p-2">
                    <h5 className="card-title mb-2 page-title font-weight-bold text-secondary">
                      {clovek.jmeno} {clovek.prijmeni}
                    </h5>
                    <p className="card-text normal-font text-secondary m-0">
                      {clovek.ulice ? clovek.ulice + ", " : null} {clovek.obec},{" "}
                      {clovek.psc}
                    </p>
                    <p className="card-text normal-font text-secondary m-0">
                      {this.getBillTypeById(clovek.typBillId)}{" "}
                      {clovek.cislo ? `- číslo: ${clovek.cislo}` : null} -{" "}
                      {clovek.cena} Kč
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Fragment>
    ) : (
      <h1>Načítání...</h1>
    );
  }
}

export default Group;
