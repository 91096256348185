import React, { Component, Fragment } from "react";
import Iframe from "react-iframe";

class Calendar extends Component {
  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 responsive-table-scroll p-2">
            <Iframe
              url="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FPrague&amp;src=bGlvbjdjNUBnbWFpbC5jb20&amp;src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;src=Y3MuY3plY2gjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%23039BE5&amp;color=%2333B679&amp;color=%230B8043&amp;mode=WEEK&amp;showPrint=1&amp;showDate=1&amp;showTz=0"
              width="1000px"
              height="600px"
              className="calendar-cover"
              display="block"
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Calendar;
