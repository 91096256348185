import React, { Component, Fragment } from "react";
import TreatmentInvoicePDF from "./treatmentInvoicePDF";
import TreatmentForm from "./treatmentForm";
import { parseDate, parseTime } from "../../utils/parseDate";
import { renderPdf } from "../../utils/renderPDF";

class TreatmentListHeader extends Component {
  render() {
    const {
      treatments,
      treatment,
      treatmentTypes,
      onDelete,
      editRendered,
      onEdit,
      pacient,
    } = this.props;

    return (
      <Fragment>
        <div
          className="card-header treatment-header"
          id={`heading${treatment._id}`}
        >
          <span className="text-left text-decoration-none text-dark">
            <div className="row align-items-center">
              <div className="col-lg-1 col-sm-12">
                {`#${treatments.indexOf(treatment) + 1}`}
              </div>
              <div
                className="col-lg-4 col-sm-12 font-weight-bold"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${treatment._id}`}
                aria-expanded="true"
                aria-controls={`collapse${treatment._id}`}
              >
                {treatmentTypes.find((e) => e._id === treatment.typ).nazev}
              </div>
              <div className="col-lg-2 col-sm-12">
                {parseDate(treatment.datum)}
              </div>
              <div className="col-lg-2 col-sm-12">{`${parseTime(
                treatment.cas.od
              )} - ${parseTime(treatment.cas.do)}`}</div>
              <div className="col-lg-1 col-sm-12">
                <button
                  className="btn btn-info btn-sm"
                  data-toggle="collapse"
                  data-target={`#edit-${treatment._id}`}
                  aria-expanded="true"
                  aria-controls={`edit-${treatment._id}`}
                  onClick={() => onEdit(treatment._id)}
                >
                  Editovat
                </button>
              </div>
              <div className="col-lg-1 col-sm-12">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => onDelete(treatment)}
                >
                  Smazat
                </button>
              </div>
              <div className="col-lg-1 col-sm-12">
                {renderPdf(
                  <TreatmentInvoicePDF
                    treatment={treatment}
                    pacient={pacient}
                  />,
                  `Doklad ${treatment.vyuctovani.cislo}`
                )}
              </div>
            </div>
          </span>
        </div>

        <div
          id={`edit-${treatment._id}`}
          className="collapse"
          aria-labelledby="headingOne"
          data-parent="#treatmentList"
        >
          {editRendered === treatment._id && (
            <div className="card-body treatmentForm">
              <TreatmentForm id={treatment._id} {...this.props} />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default TreatmentListHeader;
