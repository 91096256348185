import http from "./httpService";

const url = "/groups";
const types_url = "/group_types";

export async function getGroups() {
  const { data } = await http.get(url);
  return data;
}

export async function getGroupTypes() {
  const { data } = await http.get(types_url);
  return data;
}

export async function getGroup(id) {
  const { data } = await http.get(`${url}/${id}`);
  return data;
}

export function saveGroup(group) {
  const data = { ...group };

  if (group._id) {
    delete data._id;
    data.lidi.forEach((clovek) => {
      delete clovek._id;
      return clovek;
    });

    return http.put(`${url}/${group._id}`, data);
  }

  return http.post(url, data);
}

export function deleteGroup(id) {
  return http.delete(`${url}/${id}`);
}
