import React, { Component } from "react";
import { Page, Text, Document, Image, View } from "@react-pdf/renderer";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

import topImg from "../assets/topImg.png";
import styles from "../utils/pdfStyles";
import moment from "moment";

class InvoicePDF extends Component {
  render() {
    const { data, odberatel, faktura } = this.props;

    const finalPrice = data.reduce((acc, cur) => {
      return acc + cur.cena;
    }, 0);

    return (
      <Document>
        <Page style={styles.page}>
          <View>
            <Image style={styles.image} source={topImg} />
            <Text style={styles.fakturaText}>Faktura {faktura}</Text>
          </View>
          <View style={styles.cover}>
            <View style={styles.stuff}>
              <Text style={styles.stuffTitle}>Dodavatel:</Text>
              <Text style={styles.stuffCompany}>Fyzioterapie</Text>
              <Text style={styles.stuffCompany}>Leona Zavadilová</Text>
              <Text style={styles.stuffInfo}>U Tří lvů 294/4</Text>
              <Text style={styles.stuffInfo}>České Budějovice</Text>
              <Text style={styles.stuffInfo}>370 01</Text>
              <Text style={styles.stuffIco}>IČ 719 45 881</Text>
              <Text>274728015/0300</Text>
              <Text>+420 608 871 080</Text>
              <Text>leonazavadilova@email.cz</Text>
            </View>
            <View style={styles.stuff}>
              <Text style={styles.stuffTitle}>Odběratel:</Text>
              <Text style={styles.stuffCompany}>
                {odberatel.firma ? odberatel.firma : null}
              </Text>
              <Text style={styles.stuffCompany}>
                {odberatel.jmeno} {odberatel.prijmeni}
              </Text>
              <Text style={styles.stuffInfo}>
                {odberatel.ulice ? odberatel.ulice : null}
              </Text>
              <Text style={styles.stuffInfo}>{odberatel.obec}</Text>
              <Text style={styles.stuffInfo}>{odberatel.psc}</Text>
              <Text style={styles.stuffIco}>IČ {odberatel.ico}</Text>
              <Text>{odberatel.cisloUctu}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.date}>
              datum vystavení faktury:{" "}
              <Text style={styles.bold}>{moment().format("DD.MM.YYYY")}</Text>
            </Text>
          </View>
          <View style={styles.table}>
            <Table data={data}>
              <TableHeader>
                <TableCell>
                  <Text style={styles.tableHeader}>Typ</Text>
                </TableCell>
                <TableCell>
                  <Text style={styles.tableHeader}>Cena</Text>
                </TableCell>
                <TableCell>
                  <Text style={styles.tableHeader}>Datum</Text>
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  getContent={(data) => (
                    <Text style={styles.tableBody}>{data.typ}</Text>
                  )}
                />
                <DataTableCell
                  getContent={(data) => (
                    <Text style={styles.tableBody}>{data.cena} Kč</Text>
                  )}
                />
                <DataTableCell
                  getContent={(data) => (
                    <Text style={styles.tableBody}>{data.datum}</Text>
                  )}
                />
              </TableBody>
            </Table>
            <Table>
              <TableHeader>
                <TableCell>
                  <Text style={styles.tableBottom}>Celkem</Text>
                </TableCell>
                <TableCell>
                  <Text style={styles.tableBottom}>{finalPrice} Kč</Text>
                </TableCell>
                <TableCell></TableCell>
              </TableHeader>
            </Table>
          </View>
          <View style={styles.bottomSentences}>
            <Text>
              Fakturuji za výše zmíněné služby pro{" "}
              <Text style={styles.bold}>
                {odberatel.jmeno} {odberatel.prijmeni}{" "}
              </Text>
              za <Text style={styles.bold}>{finalPrice} Kč.</Text>
            </Text>
            <Text>Nejsem plátce DPH.</Text>
            <Text>Se srdečným pozdravem Leona Zavadilová</Text>
          </View>
        </Page>
      </Document>
    );
  }
}

export default InvoicePDF;
