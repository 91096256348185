import React, { Component, Fragment } from "react";

import {
  getGroups,
  deleteGroup,
  getGroupTypes,
} from "../services/groupService";
import { parseDate, parseTime } from "../utils/parseDate";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import GroupForm from "./groupStuff/groupForm";
import GroupsTable from "./groupStuff/groupsTable";
import Pagination from "./common/pagination";
import SearchBox from "./common/searchBox";
import { toast } from "react-toastify";
import { paginate } from "../utils/paginate";
import _ from "lodash";
import ListGroup from "./common/listGroup";

class Groups extends Component {
  state = {
    groups: [],
    groupTypes: [],
    sortColumn: { path: "datum", additionalPath: "cas.od", order: "desc" },
    edit: "",
    newOne: false,
    pageSize: 8,
    currentPage: 1,
    searchQuery: "",
    selected: undefined,
  };

  async componentDidMount() {
    this.setState({
      groups: this.parseGroupDate(await getGroups()),
      groupTypes: await getGroupTypes(),
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.edit !== prevState.edit ||
      this.state.newOne !== prevState.newOne
    )
      this.setState({ groups: this.parseGroupDate(await getGroups()) });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleSearch = (searchQuery) => {
    this.setState({ searchQuery, selected: undefined, currentPage: 1 });
  };

  handleTypeSelect = (item) => {
    this.setState({ selected: item, searchQuery: "", currentPage: 1 });
  };

  handleEdit = (group) => {
    const edit = this.state.edit;

    if (edit === group._id) {
      return this.setState({ edit: "" });
    }

    this.setState({ edit: group._id, newOne: false });
  };

  handleEditNewOne = () => {
    const newOne = this.state.newOne ? false : true;

    this.setState({ newOne, edit: "" });
  };

  handleDelete = async (group) => {
    if (window.confirm("Opravdu chceš smazat tuto skupinu?")) {
      const originalGroups = [...this.state.groups];

      const groups = this.state.groups.filter((p) => p._id !== group._id);

      // PAGE MOVE FIX
      const sorted = _.orderBy(
        this.state.groups,
        [this.state.sortColumn.path],
        [this.state.sortColumn.order]
      );
      if (
        _.last(sorted)._id.toString() === group._id.toString() &&
        groups.length % this.state.pageSize === 0
      ) {
        this.setState({ groups, currentPage: this.state.currentPage - 1 });
      } // ensures that pageNumber will change when last item of that page is deleted
      // /PAGE MOVE FIX

      this.setState({ groups });

      try {
        await deleteGroup(group._id);
        toast.success("Skupina smazána");
      } catch (e) {
        if (e.response && e.response.status === 404) {
          toast.error(e.response.data);
        }
        this.setState({ groups: originalGroups });
      }
    }
  };

  didSave = () => {
    this.setState({ edit: "", newOne: false });
  };

  parseGroupDate(data) {
    return data.map((index) => {
      index.datum = parseDate(index.datum);
      index.cas.od = parseTime(index.cas.od);
      index.cas.do = parseTime(index.cas.do);
      return index;
    });
  }

  getData = () => {
    const {
      groups,
      sortColumn,
      currentPage,
      pageSize,
      searchQuery,
      selected,
    } = this.state;

    let filtered = groups;

    if (searchQuery) {
      filtered = filtered.filter((g) => g.datum.includes(searchQuery));
    }

    if (selected) {
      filtered = filtered.filter((g) => g.typ._id === selected._id);
    }

    const sorted = _.orderBy(
      filtered,
      [sortColumn.path, sortColumn.additionalPath],
      [sortColumn.order, sortColumn.order]
    );

    const paginated = paginate(sorted, currentPage, pageSize);

    return { data: paginated, totalCount: filtered.length };
  };

  render() {
    const {
      sortColumn,
      pageSize,
      currentPage,
      searchQuery,
      selected,
      groupTypes,
    } = this.state;
    const { data, totalCount } = this.getData();

    return groupTypes.length > 0 ? (
      <Fragment>
        <div className="row align-items-center mb-2">
          <div className="col-6">
            <h1 className="page-title m-0">Skupiny</h1>
          </div>
          <div className="col-6 text-right">
            <button
              className="btn btn-success m-0"
              onClick={this.handleEditNewOne}
            >
              Nová skupina
            </button>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-sm-12">
            <SearchBox
              value={searchQuery}
              placeholder="Vyhledávat datum..."
              onSearch={this.handleSearch}
            />
          </div>
          <div className="col-lg-6 col-sm-12 text-right">
            <p className="items-shown m-0">
              Zobrazeno {totalCount} skupinových cvičení
            </p>
          </div>
        </div>
        {this.state.newOne && (
          <div className="row mt-3">
            <div className="col-12">
              <div className="collapse show">
                <div className="treatmentForm mb-5">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <GroupForm
                      {...this.props}
                      id="new"
                      didSave={this.didSave}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.edit && (
          <div className="row mt-3">
            <div className="col-12">
              <div className="collapse show">
                <div className="treatmentForm mb-5">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <GroupForm
                      {...this.props}
                      id={this.state.edit}
                      didSave={this.didSave}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            <ListGroup
              selected={selected}
              onItemSelect={this.handleTypeSelect}
              default="Všechny typy"
              items={groupTypes}
            />
          </div>
          <div className="col-lg-10 col-sm-12">
            <GroupsTable
              data={data}
              sortColumn={sortColumn}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
            />
            <Pagination
              totalItems={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </Fragment>
    ) : (
      <h1>Načítání...</h1>
    );
  }
}

export default Groups;
