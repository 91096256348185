import React, { Component, Fragment } from "react";
import { getPacient } from "../services/pacientsService";
import { parseDate } from "../utils/parseDate";
import { renderPdf } from "../utils/renderPDF";
import { Link } from "react-router-dom";
import TopInfo from "./pacientStuff/topInfo";
import AnamnezaInfo from "./pacientStuff/anamnezaInfo";
import PacientTreatments from "./pacientStuff/pacientTreatments";
import {
  getPacientTreatments,
  getTreatmentTypes,
  getBillTypes,
  deleteTreatment,
} from "../services/treatmentService";
import { toast } from "react-toastify";
import _ from "lodash";
import PacientPDF from "./pacientStuff/pacientPDF";

class Pacient extends Component {
  state = {
    pacient: {
      anamneza: {},
      adresa: {},
    },
    treatments: [],
    treatmentTypes: [],
    billTypes: [],
    editRendered: false,
  };

  async componentDidMount() {
    this.setState({
      pacient: this.parsePacientDate(
        await getPacient(this.props.match.params.id)
      ),
      treatments: _.sortBy(
        await getPacientTreatments(this.props.match.params.id),
        ["datum"]
      ),
      treatmentTypes: await getTreatmentTypes(),
      billTypes: await getBillTypes(),
    });
  }

  handleDelete = async (treatment) => {
    if (window.confirm("Opravdu chceš smazat toto ošetření?")) {
      const originalTreatments = [...this.state.treatments];

      const treatments = this.state.treatments.filter(
        (e) => e._id !== treatment._id
      );

      this.setState({ treatments });

      try {
        await deleteTreatment(treatment._id);
        toast.success("Ošetření smazáno");
      } catch (e) {
        if (e.response && e.response.status === 404) {
          toast.error(e.response.data);
        }
        this.setState({ treatments: originalTreatments });
      }
    }
  };

  handleEdit = (treatmentId) => {
    this.setState({ editRendered: treatmentId });
  };

  parsePacientDate(data) {
    data.datum = parseDate(data.datum);
    return data;
  }

  render() {
    const { pacient, treatments, treatmentTypes, editRendered, billTypes } =
      this.state;

    return Object.keys(pacient).length > 3 ? (
      <Fragment>
        <div className="row align-items-center">
          <div className="col-lg-6 col-sm-12">
            <h5 className="m-0 normal-font">{pacient.datum}</h5>
          </div>
          <div className="col-lg-6 col-sm-12 text-right">
            {renderPdf(
              <PacientPDF
                pacient={pacient}
                treatments={treatments}
                treatmentTypes={treatmentTypes}
                billTypes={billTypes}
              />,
              `${pacient.jmeno} ${pacient.prijmeni} - ${pacient.rodneCislo}`
            )}
            <Link
              to={`/pacienti/edit/${pacient._id}`}
              className="btn btn-info mx-3"
            >
              Editovat
            </Link>
            <Link to="/pacienti" className="btn btn-secondary">
              Zpět
            </Link>
          </div>
        </div>
        <hr className="col-xs-12"></hr>
        <TopInfo pacient={pacient} />
        <hr className="col-xs-12"></hr>
        <AnamnezaInfo pacient={pacient} />
        <hr className="col-xs-12"></hr>
        <PacientTreatments
          {...this.props}
          treatments={treatments}
          treatmentTypes={treatmentTypes}
          editRendered={editRendered}
          billTypes={billTypes}
          pacient={pacient}
          onDelete={this.handleDelete}
          onEdit={this.handleEdit}
        />
      </Fragment>
    ) : (
      <h1>Načítání...</h1>
    );
  }
}

export default Pacient;
