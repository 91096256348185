import React, { Component, Fragment } from "react";
import { getWhisperers, deleteWhisperer } from "../services/whispererService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class Whisperers extends Component {
  state = {
    whisperers: [],
  };

  async componentDidMount() {
    this.setState({ whisperers: await getWhisperers() });
  }

  handleDelete = async (id) => {
    const orginalWhisperers = [...this.state.whisperers];

    const whisperers = this.state.whisperers.filter((w) => w._id !== id);

    this.setState({ whisperers });

    try {
      await deleteWhisperer(id);
      toast.success("Našeptávač odstaněn");
    } catch (e) {
      if (e.response && e.response.status === 404) {
        toast.error(e.response.data);
      }
      this.setState({ whisperers: orginalWhisperers });
    }
  };

  render() {
    const { whisperers } = this.state;

    return (
      <Fragment>
        <div className="row align-items-center mb-3">
          <div className="col-6">
            <h1 className="m-0 page-title">Našeptávač</h1>
          </div>
          <div className="col-6 text-right">
            <Link to="/naseptavac/new" className="btn btn-success">
              Přidat
            </Link>
          </div>
        </div>

        <div className="responsive-table-scroll">
          <table className="table table-bordered">
            <thead>
              <tr className="d-flex">
                <th className="col-lg-1 col-sm-12">Zkratka</th>
                <th className="col-lg-9 col-sm-12">Text</th>
                <th className="col-lg-2 col-sm-12"></th>
              </tr>
            </thead>
            <tbody>
              {whisperers.map((w) => (
                <tr key={w._id} className="d-flex">
                  <td className="col-lg-1 col-sm-12">
                    <Link
                      to={`/naseptavac/${w._id}`}
                      className="text-decoration-none normal-font font-weight-bold"
                    >
                      {w.zkratka}{" "}
                    </Link>
                  </td>
                  <td className="col-lg-9 col-sm-12">
                    <pre className="card-text normal-font text-secondary">
                      {w.text}
                    </pre>
                  </td>
                  <td className="col-lg-2 col-sm-12 text-center">
                    <button
                      onClick={() => this.handleDelete(w._id)}
                      className="btn btn-danger"
                    >
                      Smazat
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default Whisperers;
