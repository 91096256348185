import React, { Component, Fragment } from "react";
import { getLector } from "../../services/lectorsService";
import { getBillTypes } from "../../services/treatmentService";
import { parseTime, parseDate } from "../../utils/parseDate";
import { Link } from "react-router-dom";

class Lector extends Component {
  state = {
    lector: {
      typ: {},
      lidi: [],
      nekdoJiny: {},
      cas: {},
    },
    billTypes: [],
  };

  async componentDidMount() {
    try {
      const lector = this.parseLectorDate(
        await getLector(this.props.match.params.id)
      );
      this.setState({ lector, billTypes: await getBillTypes() });
    } catch (e) {
      this.props.history.replace("/not-found");
    }
  }

  parseLectorDate(data) {
    data.datum = parseDate(data.datum);
    data.cas.od = parseTime(data.cas.od);
    data.cas.do = parseTime(data.cas.do);

    return data;
  }

  getBillTypeById(_id) {
    const billType = this.state.billTypes.find((b) => b._id === _id);
    return billType.nazev;
  }

  render() {
    const { lector } = this.state;

    let childPlace = 0;
    return Object.keys(lector).length > 4 ? (
      <Fragment>
        <div className="row">
          <div className="col-12 text-right">
            <Link to="/lektorstvi" className="btn btn-secondary">
              Zpět
            </Link>
          </div>
        </div>
        <hr className="col-xs-12"></hr>
        <div className="row">
          <div className="col-12 pacient-topInfo">
            <h1 className="page-title">{lector.typ.nazev}</h1>
            <h3 className="font-weight-normal normal-font">{lector.datum}</h3>
            <h4 className="font-weight-normal normal-font">
              {lector.cas.od} - {lector.cas.do}
            </h4>
            <h4 className="font-weight-bold normal-font">
              Počet účastníků:{" "}
              <span className="font-weight-light">{lector.pocetLidi}</span>{" "}
            </h4>
            <h4 className="font-weight-bold normal-font">
              Organizátor:{" "}
              <span className="font-weight-light">{lector.organizator}</span>{" "}
            </h4>
          </div>
        </div>
        <hr className="col-xs-12"></hr>
        <div className="row">
          {lector.lidi &&
            lector.lidi.length > 0 &&
            lector.lidi.map((clovek) => {
              childPlace++;
              return (
                <div
                  className={`col-lg-6 col-sm-12 p-0 ${
                    childPlace % 2 === 0 ? "pl-lg-2" : "pr-lg-2"
                  }`}
                  key={clovek._id}
                >
                  <div className="pacient-anamneza-info mb-3">
                    <div className="card-body p-2">
                      <h5 className="card-title mb-2 page-title font-weight-bold text-secondary">
                        {clovek.jmeno} {clovek.prijmeni}
                      </h5>
                      <p className="card-text normal-font text-secondary m-0">
                        {clovek.ulice ? clovek.ulice + ", " : null}{" "}
                        {clovek.obec}, {clovek.psc}
                      </p>
                      <p className="card-text normal-font text-secondary m-0">
                        {this.getBillTypeById(clovek.typBillId)}{" "}
                        {clovek.cislo ? `- číslo: ${clovek.cislo}` : null} -{" "}
                        {clovek.cena} Kč
                      </p>
                      <p className="card-text normal-font text-secondary m-0">
                        {clovek.email ? clovek.email + ", " : null}{" "}
                        {clovek.telefon}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {lector.nekdoJiny && Object.keys(lector.nekdoJiny).length > 0 && (
          <div className="row">
            <div className="col-12 p-0">
              <div className="pacient-anamneza-info mb-3">
                <div className="card-body p-2">
                  <h5 className="card-title mb-2 page-title font-weight-bold">
                    {lector.nekdoJiny.jmeno} {lector.nekdoJiny.prijmeni}
                  </h5>
                  <h6 className="card-text mb-2 page-title font-weight-bold text-secondary">
                    Adresa:{" "}
                    <span className="font-weight-normal text-secondary">
                      {lector.nekdoJiny.ulice
                        ? lector.nekdoJiny.ulice + ", "
                        : null}{" "}
                      {lector.nekdoJiny.obec}, {lector.nekdoJiny.psc}
                    </span>
                  </h6>
                  <h6 className="card-text mb-2 page-title font-weight-bold text-secondary">
                    IČO:{" "}
                    <span className="font-weight-normal text-secondary">
                      {lector.nekdoJiny.ico}
                    </span>
                  </h6>
                  <h6 className="card-text mb-2 page-title font-weight-bold text-secondary">
                    Číslo účtu:{" "}
                    <span className="font-weight-normal text-secondary">
                      {lector.nekdoJiny.cisloUctu}
                    </span>
                  </h6>
                  <h6 className="card-text mb-2 page-title font-weight-bold text-secondary">
                    Cena:{" "}
                    <span className="font-weight-normal text-secondary">
                      {lector.nekdoJiny.cena} Kč
                    </span>
                  </h6>
                  <h6 className="card-text mb-2 page-title font-weight-bold text-secondary">
                    Faktura č.
                    <span className="font-weight-normal text-secondary">
                      {lector.nekdoJiny.cislo
                        ? ` ${lector.nekdoJiny.cislo}`
                        : null}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    ) : (
      <h1>Načítání...</h1>
    );
  }
}

export default Lector;
