import React, { Component } from "react";
import _ from "lodash";

class TableBody extends Component {
  renderCell = (item, column) => {
    return (
      <td key={column.path || column.key}>
        {column.content ? column.content(item) : _.get(item, column.path)}
      </td>
    );
  };

  render() {
    return (
      <tbody>
        {this.props.data.map((item) => (
          <tr key={item._id}>
            {this.props.columns.map((column) => this.renderCell(item, column))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
