import React, { Fragment } from "react";
import Form from "./common/form";
import Joi from "joi-browser";
import { getWhisperer, saveWhisperer } from "../services/whispererService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

class WhispererForm extends Form {
  state = {
    data: {
      zkratka: "",
      text: "",
    },
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    zkratka: Joi.string()
      .max(5)
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
    text: Joi.string()
      .required()
      .error((errors) => {
        this.joiCzechMessages(errors);
        return errors;
      }),
  };

  populateWhisperer = async () => {
    try {
      const id = this.props.match.params.id;
      if (id === "new") return;

      const treatment = await getWhisperer(id);
      this.setState({
        data: {
          _id: treatment._id,
          zkratka: treatment.zkratka,
          text: treatment.text,
        },
      });
    } catch (e) {
      if (e.response && e.response.status === 404)
        return this.props.history.replace("/not-found");
    }
  };

  async componentDidMount() {
    await this.populateWhisperer();
  }

  doSubmit = async () => {
    try {
      await saveWhisperer(this.state.data);
      toast.success("Našeptávač uložen");
      this.props.history.push("/naseptavac");
    } catch (e) {
      if (e.response && e.response.status === 400) {
        toast.error(e.response.data);
      }
    }
  };

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 text-right">
            <Link to="/naseptavac" className="btn btn-secondary">
              Zpět
            </Link>
          </div>
        </div>
        <hr className="col-xs-12"></hr>
        <div className="treatmentForm mb-3">
          <h1 className="page-title">
            {this.props.match.params.id === "new"
              ? "Nový našeptávač"
              : "Upravit našeptávač"}
          </h1>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("zkratka", "Zkratka")}
            {this.renderTextArea("text", "Text")}
            {this.renderButton("Přidat")}
          </form>
        </div>
      </Fragment>
    );
  }
}

export default WhispererForm;
