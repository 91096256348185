import React, { Component, Fragment } from "react";

class AnamnezaInfo extends Component {
  anamnezaPaths = [
    {
      name: "srdce",
      label: "Srdce",
    },
    {
      name: "gynekologieAndrologie",
      label: "Gynekologie / Andrologie",
    },
    {
      name: "zazivani",
      label: "Zažívání",
    },
    {
      name: "kosti",
      label: "Kosti",
    },
    {
      name: "krev",
      label: "Krev",
    },
    {
      name: "urologie",
      label: "Urologie",
    },
    {
      name: "nadory",
      label: "Nádory",
    },
    {
      name: "plice",
      label: "Plíce",
    },
    {
      name: "operace",
      label: "Operace",
    },
    {
      name: "medikamenty",
      label: "Medikamenty",
    },
    {
      name: "ostatni",
      label: "Ostatní",
    },
    {
      name: "cil",
      label: "Cíl",
    },
  ];

  render() {
    const { pacient } = this.props;

    const { anamneza } = pacient;

    let childPlace = 0;
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 p-0 pacient-anamneza-info mb-3">
            <div className="card-body p-2">
              <h5 className="card-title m-0 page-title font-weight-bold text-secondary">
                Kineziologický rozbor:
              </h5>
              <pre className="card-text normal-font text-secondary">
                {pacient.rozbor}
              </pre>
            </div>
          </div>
        </div>
        <div className="row">
          {anamneza &&
            this.anamnezaPaths.map((e, index) => {
              if (anamneza[e.name]) {
                childPlace++;
                return (
                  <div
                    className={`col-lg-6 col-sm-12 p-0 ${
                      childPlace % 2 === 0 ? "pl-lg-2" : "pr-lg-2"
                    }`}
                    key={e.name}
                  >
                    <div className="pacient-anamneza-info mb-3">
                      <div className="card-body p-2">
                        <h5 className="card-title m-0 page-title font-weight-bold text-secondary">
                          {e.label}:
                        </h5>
                        <p className="card-text normal-font text-secondary">
                          {anamneza[e.name]}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
        </div>
      </Fragment>
    );
  }
}

export default AnamnezaInfo;
