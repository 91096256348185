import _ from "lodash";

export function paginate(items, currentPage, pageSize) {
  const startIndex = (currentPage - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}

const PAGINATION_MAX_RANGE = 7;
export const DOTS = "...";

export const getRange = (minPage, maxPage, pageNumber) => {
  const isLTEMaxRange = maxPage - minPage <= PAGINATION_MAX_RANGE - 1;

  // if range is less than or equal to MAX_RANGE -> return the range
  if (isLTEMaxRange) return [...Array(maxPage + 1).keys()].slice(minPage);

  const range = [minPage, maxPage];

  // default range start & end (+-1 around the pageNumber)
  let start = Math.max(minPage + 1, pageNumber - 1);
  let end = Math.min(maxPage - 1, pageNumber + 1);

  // start & end adjustment if they are too close to the minPage or maxPage
  if (pageNumber - 1 <= 3) {
    start = minPage + 1;
    end = start + 3;
  } else if (maxPage - pageNumber <= 3) {
    end = maxPage - 1;
    start = end - 3;
  }

  // adding middle elements
  for (let i = start; i <= end; i++) {
    range.splice(range.length - 1, 0, i);
  }

  // adding dots
  if (start > minPage + 1) range.splice(1, 0, DOTS);
  if (end < maxPage - 1) range.splice(range.length - 1, 0, DOTS);

  return range;
};
