import React, { Component } from "react";

class TreatmentListBody extends Component {
  render() {
    const { treatment } = this.props;
    const { billTypes } = this.props;

    return (
      <div
        id={`collapse${treatment._id}`}
        className="collapse"
        aria-labelledby={`heading${treatment._id}`}
        data-parent="#treatmentList"
      >
        <div className="card-body treatment-body">
          <div className="row">
            <div className="col-lg-9 col-sm-12">
              <h5 className="mb-2 page-title font-weight-bold">
                Hlavní problém:{" "}
                <span className="font-weight-normal text-secondary">
                  {treatment.hlavniProblem}
                </span>
              </h5>
              <div>
                <h5 className="font-weight-bold m-0 page-title">
                  Popis terapie:
                </h5>
                <pre className="card-text normal-font text-secondary">
                  {treatment.popis}
                </pre>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 border-left">
              <h2>
                <span className="font-weight-normal page-title">
                  {
                    billTypes.find(
                      (e) => e._id === treatment.vyuctovani.typBill
                    ).nazev
                  }
                </span>
              </h2>
              <h3>
                <span className="font-weight-bold conforta-font">
                  {treatment.vyuctovani.cislo}
                </span>
              </h3>
              <h1>
                <span className="badge badge-dark">
                  {treatment.vyuctovani.cena} Kč
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TreatmentListBody;
