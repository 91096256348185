import { StyleSheet, Font } from "@react-pdf/renderer";

import RobotoLight from "../fonts/Roboto-Light.ttf";
import RobotoLightItalic from "../fonts/Roboto-LightItalic.ttf";
import RobotoMedium from "../fonts/Roboto-Medium.ttf";
import RobotoThin from "../fonts/Roboto-Thin.ttf";
import Montserrat from "../fonts/Montserrat-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoLightItalic", src: RobotoLightItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoThin", src: RobotoThin });
Font.register({ family: "Montserrat", src: Montserrat });

export default StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
  },
  image: {
    width: "100%",
  },
  fakturaText: {
    marginTop: "20px",
    textAlign: "center",
    fontFamily: "RobotoMedium",
  },
  cover: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
    fontFamily: "RobotoLight",
    marginTop: "40",
  },
  stuff: {
    fontSize: 10,
  },
  stuffTitle: {
    fontSize: 20,
    marginBottom: "8",
  },
  stuffCompany: {
    fontSize: 15,
    fontFamily: "RobotoMedium",
    marginBottom: "3",
  },
  stuffInfo: {
    fontSize: 12,
    marginBottom: "3",
  },
  stuffIco: {
    fontSize: 15,
    marginBottom: "10",
    fontFamily: "RobotoThin",
  },
  date: {
    fontSize: 12,
    fontFamily: "RobotoThin",
    textAlign: "center",
    marginTop: "20",
  },
  table: {
    margin: "20 40",
  },
  tableHeader: {
    backgroundColor: "#e4f3fb",
    fontFamily: "Montserrat",
    color: "grey",
    padding: "3",
    fontSize: 12,
  },
  tableBody: {
    fontFamily: "RobotoLight",
    padding: "3",
    fontSize: 10,
  },
  tableBottom: {
    fontFamily: "RobotoMedium",
    padding: "3",
    fontSize: 10,
  },
  bottomSentences: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "RobotoLight",
    fontSize: 15,
    margin: "20 40",
  },
  bold: {
    fontFamily: "RobotoMedium",
  },
  italic: {
    fontFamily: "RobotoLightItalic",
  },
  light: {
    fontFamily: "RobotoLight",
  },
  pacientInfoCover: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "RobotoLight",
    margin: "20 40",
    borderBottom: "1 solid #000",
  },
  anamnezaCover: {
    fontFamily: "RobotoLight",
    margin: "0 40",
  },
  anamnezaStuff: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  anamnezaStuffInsideLeft: {
    width: "50%",
    paddingRight: 20,
    borderRight: "1 solid #000",
  },
  anamnezaStuffInsideRight: {
    width: "50%",
    paddingLeft: 20,
    borderLeft: "1 solid #000",
  },
  anamnezaStuffInfo: {
    fontSize: 12,
    marginBottom: "5",
  },
  rozborText: {
    fontSize: 10,
  },
  topStuff: {
    fontSize: 20,
    marginBottom: "8",
    margin: "20 40",
    fontFamily: "RobotoLight",
    color: "#c4c0c0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  kinezRozbor: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "RobotoLight",
    fontSize: 15,
    margin: "0 40 20 40",
    paddingBottom: "5",
    borderBottom: "1 dashed #000",
  },
  terapie: {
    margin: "10 40",
  },
  terapieTopTexts: {
    fontSize: 12,
    fontFamily: "RobotoMedium",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bottomFixed: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  treatmentInvoicePDFPage: {
    fontSize: 15,
    fontFamily: "RobotoMedium",
  },
  treatmentInvoicePDFNumber: {
    position: "absolute",
    top: "7mm",
    left: "72mm",
  },
  treatmentInvoicePDFDate: {
    position: "absolute",
    top: "7mm",
    left: "112mm",
  },
  treatmentInvoicePDFPrice: {
    position: "absolute",
    top: "20mm",
    left: "105mm",
  },
  treatmentInvoicePDFPriceTotal: {
    position: "absolute",
    top: "38.5mm",
    left: "105mm",
  },
  treatmentInvoicePDFPriceWords: {
    position: "absolute",
    top: "48mm",
    left: "16mm",
  },
  treatmentInvoicePDFName: {
    position: "absolute",
    top: "59mm",
    left: "20mm",
    fontSize: 10,
  },
  treatmentInvoicePDFReason: {
    position: "absolute",
    top: "74mm",
    left: "23mm",
  },
});
