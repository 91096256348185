import http from "./httpService";

const url = "/pacients";

export async function getPacients() {
  const { data } = await http.get(url);
  return data;
}

export async function getPacient(id) {
  const { data } = await http.get(`${url}/${id}`);
  return data;
}

export function savePacient(pacient) {
  const data = { ...pacient };

  if (pacient._id) {
    delete data._id;
    return http.put(`${url}/${pacient._id}`, data);
  }

  return http.post(url, data);
}

export function deletePacient(id) {
  return http.delete(`${url}/${id}`);
}
