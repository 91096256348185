import React, { Component } from "react";

import man from "../../assets/man.png";
import woman from "../../assets/woman.png";

class TopInfo extends Component {
  render() {
    const { pacient } = this.props;

    return (
      <div className="row align-items-center pacient-topInfo">
        <div className="col-lg-1 col-sm-12">
          <img
            src={pacient.pohlavi === "muž" ? man : woman}
            alt="avatar"
            width="70"
            height="70"
          />
        </div>
        <div className="col-lg-11 col-sm-12">
          <div className="row align-items-center">
            <div className="col-12">
              <h1 className="mb-2">
                {pacient.jmeno} {pacient.prijmeni}
              </h1>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7  col-sm-12">
              <h5 className="font-weight-bold">
                Telefon:{" "}
                <span className="font-weight-normal normal-font">
                  {pacient.telefon}
                </span>
              </h5>
              <h5 className="font-weight-bold">
                Email:{" "}
                <span className="font-weight-normal normal-font">
                  {pacient.email}
                </span>
              </h5>
              <h5 className="font-weight-bold">
                Adresa:{" "}
                <span className="font-weight-normal normal-font">
                  {pacient.adresa.ulice}
                  {pacient.adresa.ulice ? "," : null} {pacient.adresa.obec},{" "}
                  {pacient.adresa.psc}
                </span>
              </h5>
            </div>
            <div className="col-lg-5  col-sm-12">
              <h5 className="font-weight-bold">
                Rodné číslo:{" "}
                <span className="font-weight-normal normal-font">
                  {pacient.rodneCislo}
                </span>
              </h5>
              <h5 className="font-weight-bold">
                IČO:{" "}
                <span className="font-weight-normal normal-font">
                  {pacient.ico || "Neuvedeno"}
                </span>
              </h5>
              <h5 className="font-weight-bold">
                DIČ:{" "}
                <span className="font-weight-normal normal-font">
                  {pacient.dic || "Neuvedeno"}
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopInfo;
