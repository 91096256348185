import React, { Component } from "react";
import TreatmentListHeader from "./treatmentListHeader";
import TreatmentListBody from "./treatmentListBody";

class TreatmentsList extends Component {
  render() {
    const {
      treatments,
      treatmentTypes,
      editRendered,
      billTypes,
      onDelete,
      onEdit,
    } = this.props;

    return (
      <div className="accordion mb-5 treatment-cover" id="treatmentList">
        {treatments.map((treatment) => (
          <div className="card treatment-cover-inside" key={treatment._id}>
            <TreatmentListHeader
              treatment={treatment}
              treatments={treatments}
              treatmentTypes={treatmentTypes}
              onDelete={onDelete}
              onEdit={onEdit}
              editRendered={editRendered}
              {...this.props}
            />

            <TreatmentListBody treatment={treatment} billTypes={billTypes} />
          </div>
        ))}
      </div>
    );
  }
}

export default TreatmentsList;
