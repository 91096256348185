import React, { Component } from "react";
import { Page, Text, Document, View, Image } from "@react-pdf/renderer";
import invoiceImg from "../../assets/invoiceTemplatePDF.png";

import { parseDate } from "../../utils/parseDate";
import styles from "../../utils/pdfStyles";

class TreatmentInvoicePDF extends Component {
  renderNumberName(number) {
    const numberStr = number.toString();

    if (numberStr.length === 3) return `${numberNames[numberStr[0]]}sto`;

    if (numberStr.length === 4) {
      if (numberStr[1] === "0") return `${numberNames[numberStr[0]]}tisíc`; // Přesně X tisíc

      return `${numberNames[numberStr[0]]}tisíc${numberNames[numberStr[1]]}sto`;
    }

    return "";
  }

  renderTreatmentInvoice(treatment, pacient) {
    return (
      <View style={styles.treatmentInvoicePDFPage}>
        <Image style={styles.image} source={invoiceImg} />
        <Text style={styles.treatmentInvoicePDFNumber}>
          {treatment.vyuctovani.cislo}
        </Text>
        <Text style={styles.treatmentInvoicePDFDate}>
          {parseDate(treatment.datum)}
        </Text>
        <Text style={styles.treatmentInvoicePDFPrice}>
          {treatment.vyuctovani.cena},-
        </Text>
        <Text style={styles.treatmentInvoicePDFPriceTotal}>
          {treatment.vyuctovani.cena},-
        </Text>
        <Text style={styles.treatmentInvoicePDFPriceWords}>
          ~{this.renderNumberName(treatment.vyuctovani.cena)}~
        </Text>
        <Text style={styles.treatmentInvoicePDFName}>
          {`${pacient.jmeno} ${pacient.prijmeni}, ${pacient.adresa.ulice}, ${pacient.adresa.obec}, ${pacient.adresa.psc}`}
        </Text>
        <Text style={styles.treatmentInvoicePDFReason}>Fyzioterapie</Text>
      </View>
    );
  }

  render() {
    const { treatment, pacient } = this.props;

    return (
      <Document>
        <Page size="A5">
          {this.renderTreatmentInvoice(treatment, pacient)}
          {this.renderTreatmentInvoice(treatment, pacient)}
        </Page>
      </Document>
    );
  }
}

export default TreatmentInvoicePDF;

const numberNames = {
  1: "jeden",
  2: "dva",
  3: "tři",
  4: "čtyři",
  5: "pět",
  6: "šest",
  7: "sedm",
  8: "osm",
  9: "devět",
};
