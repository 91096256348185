import React, { Component, Fragment } from "react";
import TreatmentForm from "./treatmentForm";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TreatmentsList from "./treatmentsList";

class PacientTreatments extends Component {
  render() {
    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className="row align-items-center">
            <div className="col-6">
              <h2 className="page-title m-0">Ošetření</h2>
            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-success"
                data-toggle="collapse"
                data-target="#newTreatmentForm"
                aria-expanded="false"
                aria-controls="newTreatmentForm"
              >
                Nové ošetření
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="collapse" id="newTreatmentForm">
                <div className="mb-5 treatmentForm">
                  <TreatmentForm id="new" {...this.props} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TreatmentsList {...this.props} />
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
}

export default PacientTreatments;
