import React, { Component, Fragment } from "react";
import {
  getLectorTypes,
  getLectors,
  deleteLector,
} from "../services/lectorsService";
import { parseTime, parseDate } from "../utils/parseDate";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import LectorForm from "./lectorStuff/lectorForm";
import LectorTable from "./lectorStuff/lectorTable";
import Pagination from "./common/pagination";
import SearchBox from "./common/searchBox";
import ListGroup from "./common/listGroup";

import _ from "lodash";
import { paginate } from "../utils/paginate";
import { toast } from "react-toastify";

class Lectors extends Component {
  state = {
    lectors: [],
    sortColumn: { path: "datum", additionalPath: "cas.od", order: "desc" },
    currentPage: 1,
    pageSize: 8,
    search: "",
    selected: undefined,
    lectorTypes: [],
    newOne: false,
    edit: "",
  };

  async componentDidMount() {
    this.setState({
      lectors: this.parseLectorsDate(await getLectors()),
      lectorTypes: await getLectorTypes(),
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.edit !== prevState.edit ||
      this.state.newOne !== prevState.newOne
    )
      this.setState({ lectors: this.parseLectorsDate(await getLectors()) });
  }

  parseLectorsDate(data) {
    return data.map((index) => {
      index.datum = parseDate(index.datum);
      index.cas.od = parseTime(index.cas.od);
      index.cas.do = parseTime(index.cas.do);
      return index;
    });
  }

  handleEditNewOne = () => {
    const newOne = this.state.newOne ? false : true;

    this.setState({ newOne, edit: "" });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage });
  };

  handleSearch = (searchQuery) => {
    this.setState({ search: searchQuery, currentPage: 1, selected: undefined });
  };

  handleTypeChange = (item) => {
    this.setState({ selected: item, currentPage: 1, search: "" });
  };

  handleDelete = async (lector) => {
    if (window.confirm("Opravdu chceš smazat tuto lektorskou činnost??")) {
      const originalLectors = [...this.state.lectors];

      const lectors = this.state.lectors.filter((p) => p._id !== lector._id);

      // PAGE MOVE FIX
      const sorted = _.orderBy(
        this.state.lectors,
        [this.state.sortColumn.path],
        [this.state.sortColumn.order]
      );
      if (
        _.last(sorted)._id.toString() === lector._id.toString() &&
        lectors.length % this.state.pageSize === 0
      ) {
        this.setState({ lectors, currentPage: this.state.currentPage - 1 });
      } // ensures that pageNumber will change when last item of that page is deleted
      // /PAGE MOVE FIX

      this.setState({ lectors });

      try {
        await deleteLector(lector._id);
        toast.success("Lektorská činnost smazána");
      } catch (e) {
        if (e.response && e.response.status === 404) {
          toast.error(e.response.data);
        }
        this.setState({ lectors: originalLectors });
      }
    }
  };

  handleEdit = (lector) => {
    const edit = this.state.edit;

    if (edit === lector._id) {
      return this.setState({ edit: "" });
    }

    this.setState({ edit: lector._id, newOne: false });
  };

  didSave = () => {
    this.setState({ edit: "", newOne: false });
  };

  getData = () => {
    const {
      lectors,
      sortColumn,
      currentPage,
      pageSize,
      search,
      selected,
    } = this.state;

    let filtered = lectors;

    if (search) {
      filtered = filtered.filter((l) => l.datum.includes(search));
    }

    if (selected) {
      filtered = filtered.filter((l) => l.typ._id === selected._id);
    }

    const sorted = _.orderBy(
      filtered,
      [sortColumn.path, sortColumn.additionalPath],
      [sortColumn.order, sortColumn.order]
    );

    const paginated = paginate(sorted, currentPage, pageSize);

    return { data: paginated, totalCount: filtered.length };
  };

  render() {
    const {
      sortColumn,
      pageSize,
      currentPage,
      search,
      selected,
      lectorTypes,
    } = this.state;
    const { data, totalCount } = this.getData();

    return lectorTypes.length > 0 ? (
      <Fragment>
        <div className="row align-items-center mb-2">
          <div className="col-6">
            <h1 className="page-title m-0">Lektorská činnost</h1>
          </div>
          <div className="col-6 text-right">
            <button
              className="btn btn-success m-0"
              onClick={this.handleEditNewOne}
            >
              Nová činnost
            </button>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-sm-12">
            <SearchBox
              placeholder="Vyhledávat datum..."
              onSearch={this.handleSearch}
              value={search}
            />
          </div>
          <div className="col-lg-6 col-sm-12 text-right">
            <p className="items-shown m-0">
              Zobrazeno {totalCount} lektorských činností
            </p>
          </div>
        </div>
        {this.state.newOne && (
          <div className="row mt-3">
            <div className="col-12">
              <div className="collapse show">
                <div className="treatmentForm mb-5">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <LectorForm
                      id="new"
                      {...this.props}
                      didSave={this.didSave}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.edit && (
          <div className="row mt-3">
            <div className="col-12">
              <div className="collapse show">
                <div className="treatmentForm mb-5">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <LectorForm
                      {...this.props}
                      id={this.state.edit}
                      didSave={this.didSave}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            <ListGroup
              selected={selected}
              items={lectorTypes}
              default="Všechny typy"
              onItemSelect={this.handleTypeChange}
            />
          </div>
          <div className="col-lg-10 col-sm-12">
            <LectorTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
            />
            <Pagination
              totalItems={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </Fragment>
    ) : (
      <h1>Načítání...</h1>
    );
  }
}

export default Lectors;
